import React from 'react';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { theme } from '../Theme';

const HCButtonWrapper = styled('div')`
  height: 31px;
  font-size: ${theme.fontSizes.tinyParagraph};
  font-weight: ${theme.fontWeights.semibold};
  letter-spacing: 0.1em;
  width: fit-content;
  padding: 0 1rem;
  cursor: pointer;

  &.GREEN {
    background: ${theme.colors.green};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.DARK_BROWN {
    background: ${theme.colors.cafeNoir};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.LIGHT_BROWN {
    background: ${theme.colors.darkVanlilla};

    a,
    div {
      color: ${theme.colors.white};
    }
  }

  &.BEIGE {
    background: ${theme.colors.isabelline};
    a,
    div {
      color: ${theme.colors.cafeNoir};
    }
  }

  &.tiny {
    padding: 0 10px;
    font-size: 9px;
    line-height: 106.3%;
    letter-spacing: 0.1em;
  }

  :hover,
  :active {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    background: ${theme.colors.lightgrey};
    pointer-events: none;
    a,
    div {
      color: ${theme.colors.black + '66'};
    }
  }
`;

const CenterButtonContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
`;

export const HCButton = ({
  testid,
  link,
  className,
  clickCallback,
  children
}) => {
  return (
    <HCButtonWrapper
      className={cx(className, 'hc-button-wrapper')}
      onClick={clickCallback}
      data-testid={testid || ''}
    >
      <LinkEvaluator link={link}>
        <CenterButtonContent>{children}</CenterButtonContent>
      </LinkEvaluator>
    </HCButtonWrapper>
  );
};
