import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -10px;
  right: -8px;
  height: 1rem;
  width: 1rem;
  line-height: 1rem;
  font-size: 11px;
  border: 0;
  border-radius: 50%;
  background-color: ${theme.colors.brown};
  overflow: hidden;
  color: white;
`;

const Badge = ({ text, cartBadge }) => {
  return <Wrapper data-testid={cartBadge ? 'cart-badge' : 'fav-badge'} className="badge">{text}</Wrapper>;
};

export default Badge;
