import React from 'react';
import { styled } from 'linaria/react';
import { ContentPlacer } from './ContentPlacer';
import { cx, css } from 'linaria';
import { HCButton } from '../ui/HCButton';
import { theme } from '../Theme';
import { createEnum } from '../../utils/HelpFunctions';

const BoxContentWrapper = styled('div')`
  color: ${props => props.color};
  h2 {
    font-size: ${theme.fontSizes.heroTitle};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeight.heroTitle};
    text-shadow: 0px 0px 23px rgba(0, 0, 0, 0.4);
    ${theme.below.lg} {
      font-size: 50px;
      line-height: 55px;
    }
  }

  &.hero {
    /* default styling */
  }

  &.grid {
    h2 {
      font-size: ${theme.fontSizes.title};
      line-height: ${theme.lineHeight.title};
    }
  }

  &.row {
    h2 {
      font-size: ${theme.fontSizes.tinyTitle};
      line-height: ${theme.lineHeight.tinyTitle};
    }
  }

  &.menu {
    h2 {
      font-size: 25px;
      line-height: 106%;
    }
  }
`;

const handwrittenAdjustmentStyle = css`
  padding-top: 0.48em;
  line-height: 0.67em;
`;

const heroButtonStyle = css`
  margin-top: 2.5rem;
  ${theme.below.lg} {
    margin-top: 2rem;
  }
`;

const boxButtonStyle = css`
  margin-top: 1.5rem;
`;

export const BoxContentType = createEnum(['hero', 'grid', 'row', 'menu']);

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  textColor,
  topText,
  topTextFamily,
  middleText,
  middleTextFamily,
  bottomText,
  bottomTextFamily,
  buttonText,
  buttonTheme,
  handleDummyButtonClick,
  forType = 'hero' // hero || grid
}) => {
  const handwrittenFamily = 'Northwell';
  const topFamily =
    topTextFamily?.value?.value ?? topTextFamily?.value ?? 'Garamond';
  const middleFamily = middleTextFamily?.value ?? 'Garamond';
  const bottomFamily = bottomTextFamily?.value ?? 'Garamond';

  return (
    <ContentPlacer
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
    >
      <BoxContentWrapper color={textColor?.value ?? '#fff'} className={forType}>
        {topText?.value && (
          <h2
            style={{ fontFamily: topFamily }}
            className={cx(
              topFamily === handwrittenFamily && handwrittenAdjustmentStyle,
              'top'
            )}
          >
            {topText?.value}
          </h2>
        )}
        {middleText?.value && (
          <h2
            style={{ fontFamily: middleFamily }}
            className={cx(
              middleFamily === handwrittenFamily && handwrittenAdjustmentStyle,
              'middle'
            )}
          >
            {middleText?.value}
          </h2>
        )}
        {bottomText?.value && (
          <h2
            style={{ fontFamily: bottomFamily }}
            className={cx(
              bottomFamily === handwrittenFamily && handwrittenAdjustmentStyle,
              'bottom'
            )}
          >
            {bottomText?.value}
          </h2>
        )}
        {buttonText?.value && (
          <HCButton
            className={cx(
              buttonTheme?.value ?? 'BEIGE',
              forType === 'hero' ? heroButtonStyle : boxButtonStyle
            )}
            clickCallback={handleDummyButtonClick}
          >
            {buttonText?.value}
          </HCButton>
        )}
      </BoxContentWrapper>
    </ContentPlacer>
  );
};
