import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

const SubMenu = styled('div')`
  background: ${theme.colors.beige};
  margin-left: -20px;
  margin-right: -20px;
  height: 100%;
  padding: 10px 20px;
  .link-evaluator {
    height: auto;
    background: ${theme.colors.beige};
    padding: 7px 0;
    margin-bottom: 8px;
    font-size: 14px;
    &:last-child {
      margin-bottom: 8px;
    }
  }
`;

export const MenuLinks = ({ children }) => {
  return <SubMenu>{children}</SubMenu>;
};

export const MenuLinkItem = ({ linkName, link }) => {
  return <LinkEvaluator link={link.value}>{linkName?.value}</LinkEvaluator>;
};
