import React from 'react';
import { theme } from '../Theme';
import { styled } from 'linaria/react';

const SeoInnerWrapper = styled('div')`
  h2 {
    font-family: ${theme.fonts.title};
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.02em;
    font-weight: 300;
    margin-bottom: 20px;
  }
  h3 {
    font-weight: 700;
    font-family: ${theme.fonts.primary};
    font-size: 11px;
    line-height: 1.6;
  }
  p {
    margin-bottom: 20px;
    font-family: ${theme.fonts.primary};
    font-size: 11px;
    line-height: 1.6;
  }
`;

export const SeoItem = ({ title, html }) => {
  return (
    <SeoInnerWrapper>
      <h2>{title?.value}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: html?.value
        }}
      />
    </SeoInnerWrapper>
  );
};

export const Seo = ({ children }) => {
  return <div>{children}</div>;
};
