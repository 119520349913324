import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { CategoryName } from '../CategoryPage/CategoryHeader';

const TextRowWrapper = styled(MaxWidth)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;

  ${theme.below.lg} {
    padding: 0 40px;
    margin: 0;
  }
`;

export const TextRow = ({ children }) => {
  return (
    <TextRowWrapper className="text-row-wrapper">{children}</TextRowWrapper>
  );
};

const TextRowItemWrapper = styled('div')`
  margin: auto;
  width: 100%;
  max-width: 720px;
  color: ${props => props.color};

  &.journal {
    p {
      font-size: 13px;
    }
    h2 {
      text-align: center;
      font-size: ${theme.fontSizes.tinyTitle};
      line-height: ${theme.lineHeight.tinyTitle};
      font-weight: 400;
      font-family: ${theme.fonts.title};
      margin-bottom: 10px;
    }
  }

  &.content {
    p {
      margin-bottom: 10px;
      font-size: 12px;
      line-height: 22px;
    }
    h3 {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;

export const TextRowItem = ({ textColor, text, title }) => {
  return (
    <TextRowItemWrapper className="journal" color={textColor?.value ?? '#000'}>
      {title?.value && (
        <h2
          dangerouslySetInnerHTML={{
            __html: title?.value
          }}
        />
      )}
      {text?.value && (
        <div
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </TextRowItemWrapper>
  );
};

const PageName = styled(CategoryName)`
  color: ${theme.colors.brown};
  margin-top: 20px;
  padding: 10px;
  text-align: center;
  &.content-page-name {
    color: #000;
    font-size: 30px;
    max-width: 720px;
    width: 100%;
    margin: 40px auto 0 auto;
    text-align: left;
    padding: 0;
    ${theme.below.lg} {
      /* margin: 20px auto 0 auto; */
      text-align: left;
      margin: 0 auto 20px auto;
      font-size: 25px;
      line-height: 25px;
    }
  }
`;

export const ContentPageTextRowItem = ({ textColor, text, title }) => {
  return (
    <TextRowItemWrapper color={textColor?.value ?? '#000'} className="content ">
      {title?.value && (
        <PageName
          className="content-page-name"
          dangerouslySetInnerHTML={{
            __html: title?.value
          }}
        />
      )}
      {text?.value && (
        <div
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </TextRowItemWrapper>
  );
};
