import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { BoxContent, BoxContentType } from './BoxContent';
import { SliderWithButtons } from '../ui/SharedSlider';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const BoxRowWrapper = styled(MaxWidth)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  ${theme.below.lg} {
    padding: 0;
  }
  .title-and-link {
    padding: 0;
  }
  .slick-list {
    ${theme.above.lg} {
      margin: 0 -10px;
    }
    margin: 0 -5px;
  }
`;

const Wrapper = styled('div')`
  .item-wrapper {
    width: calc(100% - 20px);
    margin: auto;
    position: relative;

    ${theme.below.lg} {
      width: calc(100% - 10px);
    }
  }
`;

export const BoxRow = ({ children }) => {
  const titleAndTextChildren = filterChildrenByKey(
    children,
    'titleAndLink',
    true
  );

  const BoxRowItemChildren = filterChildrenByKey(children, 'boxRowItem', true);

  const deskstopSlides =
    BoxRowItemChildren?.length < 5 ? BoxRowItemChildren?.length : 4;
  const mobileSlides =
    BoxRowItemChildren?.length < 2 ? BoxRowItemChildren?.length : 1;

  return (
    <BoxRowWrapper className="box-row-wrapper">
      <div>{titleAndTextChildren}</div>
      <SliderWithButtons
        desktopSlides={deskstopSlides}
        mobileSlides={mobileSlides}
      >
        {BoxRowItemChildren?.map((item, index) => (
          <Wrapper key={index + '-box-row-item-wrapper'}>{item}</Wrapper>
        ))}
      </SliderWithButtons>
    </BoxRowWrapper>
  );
};

const BoxRowItemWrapper = styled('div')`
  height: 355px;
  width: 25%;

  &.overlay {
    [data-flight-image-container] {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
        pointer-events: none;
      }
    }
  }
`;

export const BoxRowItem = ({
  desktopImage,
  mobileImage,
  link,
  textColor,
  topText,
  topTextFamily,
  bottomText,
  bottomTextFamily,
  buttonText,
  buttonTheme,
  overlay
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  return (
    <BoxRowItemWrapper
      className={cx('item-wrapper', overlay?.value && 'overlay')}
    >
      <LinkEvaluator link={link}>
        <Above breakpoint="lg">
          {matches => (
            <>
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage.value}
                aspect={'260:355'}
                fillAvailableSpace
              />

              <BoxContent
                verticalPosition={{ value: 'CENTER' }}
                horizontalPosition={{ value: 'BOTTOM' }}
                textColor={textColor}
                topText={topText}
                topTextFamily={topTextFamily}
                bottomText={bottomText}
                bottomTextFamily={bottomTextFamily}
                buttonTheme={buttonTheme}
                buttonText={buttonText}
                forType={BoxContentType.ROW}
              />
            </>
          )}
        </Above>
      </LinkEvaluator>
    </BoxRowItemWrapper>
  );
};
