import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { ProductCard } from '../CategoryPage/ProductCard';
import { SliderWithButtons } from '../ui/SharedSlider';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const ProductRowWrapper = styled(MaxWidth)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .title-and-link {
    padding: 0;
  }
  li {
    list-style-type: none;
  }
`;

const Wrapper = styled('div')`
  max-width: 25%;
  width: 100%;
  ${theme.below.lg} {
    max-width: 50%;
  }
`;

const ProductSliderWrapper = styled('div')`
  ${theme.above.lg} {
    margin: 0 -10px;
    .slick-slide {
      max-width: 25%;
    }
  }
`;

const MobileScrollWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  margin: 0 -10px;
  .product-card {
    min-width: 75vw;
    .product-card-detail {
      padding-bottom: 10px;
    }
  }
`;

export const ProductRow = ({ children, showModel }) => {
  const flatChildren = children?.flat(Infinity);

  const titleAndTextChildren = filterChildrenByKey(
    flatChildren,
    'titleAndLink',
    true
  );

  const ProductRowItemChildren = filterChildrenByKey(
    flatChildren,
    'productRowItem',
    true
  );

  const deskstopSlides =
    ProductRowItemChildren?.length < 5 ? ProductRowItemChildren?.length : 4;
  const mobileSlides =
    ProductRowItemChildren?.length < 2 ? ProductRowItemChildren?.length : 1;

  return (
    <ProductRowWrapper>
      <div>{titleAndTextChildren}</div>
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <ProductSliderWrapper>
              <SliderWithButtons
                desktopSlides={deskstopSlides}
                mobileSlides={mobileSlides}
              >
                {ProductRowItemChildren?.map(product => (
                  <ProductCard
                    key={product?.props?.product?.value?.articleNumber}
                    product={product?.props?.product?.value}
                    showModel={showModel}
                  />
                ))}
              </SliderWithButtons>
            </ProductSliderWrapper>
          ) : (
            <MobileScrollWrapper>
              {ProductRowItemChildren?.map(product => (
                <ProductCard
                  key={product?.props?.product?.value?.articleNumber}
                  product={product?.props?.product?.value}
                  showModel={showModel}
                />
              ))}
            </MobileScrollWrapper>
          )
        }
      </Above>
    </ProductRowWrapper>
  );
};

export const ProductRowItem = ({ product, showModel }) => {
  return (
    <Wrapper>
      <ProductCard product={product?.value} showModel={showModel} />
    </Wrapper>
  );
};
