import React from 'react';
import { default as Breadcrumbs } from '@jetshop/ui/Breadcrumbs';
import { styled } from 'linaria/react';

const BreadcrumbsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid #f2f0eb;
  ul {
    margin: 0;
    justify-content: center;
  }
  li,
  a {
    color: #777777;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.02em;
  }
  li {
    ::after {
      content: '>';
    }
  }

  li:last-of-type {
    ::after {
      content: '';
    }
  }
`;

export const StyledBreadcrumbs = ({ breadcrumbText, breadcrumbs, parents }) => {
  const breadcrumbProps = {
    breadcrumbText,
    breadcrumbs,
    parents
  };
  return (
    <BreadcrumbsWrapper>
      <Breadcrumbs {...breadcrumbProps} />
    </BreadcrumbsWrapper>
  );
};
