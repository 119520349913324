/* eslint-disable */
import { css } from 'linaria'
import '@jetshop/ui/Theme/sanitizeCss'

css`
  :global() {
    ${resets};
  }
`

const fallbackStyles = `
  font-family: 'Proxima Nova',sans-serif;
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  visibility: visible;
`

const resets = `
    html,
    body {
      height: 100%;
      font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', 'Arial', 'sans-serif';
    }
    html,
    body,
    #root {
      min-height: 100%;
    }
    #root {
      display: flex;
      flex-direction: column;
    }
    button {
      cursor: pointer;
      padding: 0px;
      border: none;
      font: inherit;
      color: black;
    }
    ol,
    ul,
    h4,
    h3,
    h2,
    h1 {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    p,
    dd {
      margin: 0;
      padding: 0;
    }

    p, 
    a,
    h4,
    h3,
    h2,
    h1 {
      letter-spacing: 0.02em;
    }

    p {
      line-height: 1.4;
      a {
        color: inherit;
      }
    }
  
    input {
      -webkit-appearance: none;
     ::-webkit-search-cancel-button {
        -webkit-appearance: none;
        content: url(./svg/MenuClose.svg);
        height: 12px;
        width: 12px;
        cursor: pointer;
      }
    }

    .ReactModal__Body--open {
      overflow: hidden;
    }


    .ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.3)!important;
      backdrop-filter: blur(2px);
      overflow: scroll;
      z-index: 9;
    }

    body {
      ${fallbackStyles};
      -webkit-font-smoothing: antialiased;
    }
    /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
    html {
      box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }

    .price:after, .new-price:after, .old-price:after {
      content: ',-';
    }
    .price , .new-price, .old-price {
      &:after {
        content: ',-';
      }
    }

    /* Setting default scrollbar styling */
    ::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }

      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 0px;
        border-radius: 2px;
        background: #C5BBAA;
      }

      /* Hiding iframe elements
        Unclear solution that is too risky to be removed.
        But we purmit these iframes:
        - ITX chat
      */
      body > iframe:not('.itx-plugin-fram') {
        display: none;
      }
`
