import React from 'react';
import { theme } from '../Theme';
import { ReactComponent as ModernCart } from '../../svg/ModernShoppingcart.svg';
import { styled } from 'linaria/react';

const CartCircleContainer = styled('div')`
  width: 30px;
  height: 30px;
  position: absolute;
  background: ${theme.colors.isabelline};
  top: auto;
  right: 10px;
  bottom: 10px;
  left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50%;
  padding: 8px;

  svg {
    width: 14px;
    height: 12px;
  }
`;

export const CartCircle = () => {
  return (
    <CartCircleContainer>
      <ModernCart />
    </CartCircleContainer>
  );
};
