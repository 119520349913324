import CampaignBarQuery from './components/Layout/Header/CampaignBarQuery.gql';
import FooterLinksQuery from './components/Layout/Footer/FooterLinksQuery.gql';
import CategoryContentQuery from './components/ContentComponents/CategoryContentQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/Categories/HomeCategoriesQuery.gql';

export const persistedQueries = [
  {
    query: homeCategoriesQuery,
    variables: { levels: 1 }
  },
  {
    query: FooterLinksQuery,
    variables: {
      customerserviceid: 52,
      companyid: 53,
      assortmentid: 54,
      infoid: 396
    }
  },
  {
    query: CampaignBarQuery,
    variables: {
      id: 176
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 377
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 378
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 383
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 384
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 386
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 387
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 388
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 389
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 391
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 392
    }
  },
  {
    query: CategoryContentQuery,
    variables: {
      id: 393
    }
  }
];
