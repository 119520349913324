import React from 'react';
import { styled } from 'linaria/react';
import { BoxContent, BoxContentType } from './BoxContent';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';

const MenuContentWrapper = styled('div')`
  display: flex;

  .menu-content-item-wrapper + .menu-content-item-wrapper {
    margin-left: 10px;
  }
`;

export const MenuContent = ({ children }) => {
  return (
    <MenuContentWrapper className="menu-content-wrapper">
      {children}
    </MenuContentWrapper>
  );
};

export const MenuContentItemWrapper = styled('div')`
  width: 50%;
  min-width: 200px;
  max-width: 200px;
  position: relative;

  .link-evaluator {
    padding: 0px !important;
  }
`;

export const MenuContentItem = ({
  image,
  link,
  text,
  textFamily,
  closeNav
}) => {
  return (
    <MenuContentItemWrapper className="menu-content-item-wrapper">
      <LinkEvaluator link={link} linkClickCallback={closeNav?.value}>
        <Image sizes={'200'} src={image.value} aspect={'5:7'} cover />
        <BoxContent
          verticalPosition={{ value: 'MIDDLE' }}
          horizontalPosition={{ value: 'CENTER' }}
          textColor={'#fff'}
          topText={text}
          topTextFamily={textFamily}
          bottomText={null}
          bottomTextFamily={null}
          buttonTheme={null}
          buttonText={null}
          forType={BoxContentType.MENU}
        />
      </LinkEvaluator>
    </MenuContentItemWrapper>
  );
};

/**
 * Removes subcategory named "Menu content" and is level 2 from category and returns a modified category.
 * @param {object} category - Category
 * @return {object} Modified category
 */
export const filterOutMenuContentFromSubCategories = category => {
  const subcats = category?.subcategories?.filter(
    subcat =>
      !(subcat?.name?.toLowerCase() === 'menu content' && subcat?.level === 2)
  );

  if (!subcats) {
    return category;
  }

  return {
    ...category,
    subcategories: subcats
  };
};

/**
 * Removes subcategory named "Menu content" and is level 2 from root categories and returns modified categories list.
 * @param {array} categories - Array of categories
 * @return {array} Modified list with categories
 */
export const filterOutMenuContentFromRootCategories = categories => {
  const editedCategories = categories?.map(cat => {
    const subcats = cat?.subcategories?.filter(
      subcat =>
        !(subcat?.name?.toLowerCase() === 'menu content' && subcat?.level === 2)
    );

    if (!subcats) {
      return cat;
    }
    return { ...cat, subcategories: subcats };
  });

  return editedCategories;
};

/**
 * Finds if Category has subcategory named "Menu content" and is level 2 and returns id of that subcategory.
 * @param {object} category - category object
 * @return {int} Menu content category id
 */
export const findMenuContentCategoryId = category => {
  return category?.subcategories?.find(
    subcat =>
      subcat?.name?.toLowerCase() === 'menu content' && subcat?.level === 2
  )?.id;
};
