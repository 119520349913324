import { BoxGrid, BoxGridItem } from './BoxGrid';
import { ImageHero } from './ImageHero';
import {
  InspirationGrid,
  InspirationGridInfo,
  InspirationGridItem
} from './InspirationGrid';
import { ProductsPopupGrid, ProductsPopupGridItem } from './ProductsPopupGrid';
import { CampaignBar, CampaignBarItem } from './CampaignBar';
import { TitleAndLink } from './TitleAndLink';
// import { VideoHero } from './VideoHero';
import { Seo, SeoItem } from './Seo';
import { BoxRow, BoxRowItem } from './BoxRow';
import { ProductRow, ProductRowItem } from './ProductRow';
import { LinkRow, LinkRowItem } from './LinkRow';
import { TextRow, TextRowItem } from './TextRow';
import {
  ImageBoxRow,
  ImageBoxRowItem,
  ImageBoxRowTextItem
} from './ImageBoxRow';
import { ProductGrid } from './ProductGrid';
import { CustomLinks, CustomLinksItem } from '../Layout/Footer/CustomLinks';
import { MenuContent, MenuContentItem } from './MenuContent';
import { MenuLinkItem, MenuLinks } from './MenuLinks';
import { YoutubeVideo } from './YoutubeVideo';
import { Spacer } from './Spacer';

export const allContentComponents = {
  IMAGEHERO: ImageHero,
  VIDEOHERO: YoutubeVideo,
  BOXGRID: BoxGrid,
  BOXGRIDITEM: BoxGridItem,
  BOXROW: BoxRow,
  BOXROWITEM: BoxRowItem,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  PRODUCTSPOPUPGRID: ProductsPopupGrid,
  PRODUCTSPOPUPGRIDITEM: ProductsPopupGridItem,
  INSPIRATIONGRID: InspirationGrid,
  INSPIRATIONGRIDITEM: InspirationGridItem,
  INSPIRATIONGRIDINFO: InspirationGridInfo,
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  LINKROW: LinkRow,
  LINKROWITEM: LinkRowItem,
  SEO: Seo,
  SEOITEM: SeoItem,
  SPACER: Spacer,
  TEXTROW: TextRow,
  TEXTROWITEM: TextRowItem,
  IMAGEBOXROW: ImageBoxRow,
  IMAGEBOXROWITEM: ImageBoxRowItem,
  IMAGEBOXROWTEXTITEM: ImageBoxRowTextItem,
  PRODUCTGRID: ProductGrid,
  TITLEANDLINK: TitleAndLink
};

export const menuContentComponents = {
  MENUCONTENT: MenuContent,
  MENUCONTENTITEM: MenuContentItem
};

export const categoryFooterComponents = {
  SEO: Seo,
  SEOITEM: SeoItem
};

export const FooterComponents = {
  CUSTOMLINKS: CustomLinks,
  CUSTOMLINKSITEM: CustomLinksItem
};

export const FooterCompanyInfoComponents = {
  TEXTROW: TextRow,
  TEXTROWITEM: TextRowItem
};

export const uspsComponents = {
  TEXTROW: TextRow,
  TEXTROWITEM: TextRowItem
};

export const mobileMenuComponents = {
  MENULINKS: MenuLinks,
  MENULINKITEM: MenuLinkItem
};
