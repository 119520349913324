import { useProductList } from '@jetshop/core/hooks/ProductList';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import { ReactComponent as Check } from '@jetshop/ui/svg/Check.svg';
import { styled } from 'linaria/react';
import React from 'react';
import { ReactComponent as AngleRight } from '../../svg/angle-right.svg';
import { theme } from '../Theme';

const StyledDropdownMenuButton = styled(DropdownMenuButton)`
  &[data-flight-dropdown-button] {
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #ddd;
    font-size: 12px;
    border-radius: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
`;
const StyledDropdownMenuItems = styled(DropdownMenuItems)`
  &[data-flight-dropdown-items] {
    width: 100%;
    background: #f7f7f7;
    border: 1px solid #ddd;
    border-top: 0;
  }
`;
const StyledDropdownMenuItem = styled(DropdownMenuItem)`
  &[data-flight-dropdown-item] {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    &:hover {
      color: black;
    }
    svg path {
      fill: ${theme.colors.brown};
    }
  }
`;

export function SelectVariant({ product, listId }) {
  const { update } = useProductList(listId);
  const initialVariant = product.variant;

  const { selectedVariant, selectValue, getSelectedValue, validateSelection } =
    useProductVariants(product, {
      initialVariant
    });

  React.useEffect(() => {
    // When a variant is selected, call the update function from useProductList to update the list
    function setVariant(variantArticleNumber) {
      update(product.articleNumber, {
        variantArticleNumber,
        variantToReplace: product.variant?.articleNumber,
        quantity: 1
      });
    }

    const currentArticleNumber = product.variant?.articleNumber;

    if (!selectedVariant) return;

    if (currentArticleNumber !== selectedVariant.articleNumber) {
      setVariant(selectedVariant.articleNumber);
    }
  }, [product.articleNumber, product.variant, selectedVariant, update]);

  return (
    <>
      {product.variants.options.map(option => {
        const selectedValue = getSelectedValue(option);
        return (
          <DropdownMenu key={option.name}>
            <StyledDropdownMenuButton>
              {selectedValue || option.name}
              <AngleRight style={{ transform: 'rotate(90deg' }} />
            </StyledDropdownMenuButton>
            <StyledDropdownMenuItems style={{ zIndex: 9999 }}>
              {option.values.map(value => {
                const validation = validateSelection(value, option);

                return (
                  <StyledDropdownMenuItem
                    key={value}
                    disabled={validation === 'invalid'}
                    closeOnSelect={true}
                    onSelect={() => {
                      selectValue(value, option);
                    }}
                    style={{ opacity: validation !== 'invalid' ? 1 : 0.5 }}
                  >
                    {value}
                    {selectedValue === value && <Check />}
                  </StyledDropdownMenuItem>
                );
              })}
            </StyledDropdownMenuItems>
          </DropdownMenu>
        );
      })}
    </>
  );
}
