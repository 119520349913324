import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import ProductSpecifications from '@jetshop/ui/ProductSpecifications/ProductSpecifications';
import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import {
  ProductSection as UIProductSection,
  SectionHeading
} from './ProductInfo';
import { ReactComponent as Angle } from '../../svg/accord-angle.svg';

const ProductSection = styled(UIProductSection)`
  cursor: pointer;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 5px;
  margin: 0;
  font-size: 0.875rem;
  ${SectionHeading} {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.02em;
    display: flex;
    justify-items: space-between;
    align-items: center;
    margin-bottom: 0;
  }

  tr {
    border-bottom: 1px solid #f5f6f8;
    font-size: 11px;
    line-height: 28px;
    font-weight: 500;
    text-align: end;
    width: 100%;
  }
  td {
    line-height: 1.5;
    max-width: 100px;
  }
  th {
    font-weight: 400;
    line-height: 28px;
    white-space: nowrap;
    padding: 0;
    ::after {
      font-size: 11px;
      line-height: 28px;
      content: ':';
    }
  }
  li {
    list-style: none;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
`;
export const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon className="accordion-icon">
    {isOpen ? <Angle /> : <Angle style={{ transform: 'rotate(180deg)' }} />}
  </IndicatorIcon>
);

const ProductInfoAccordion = ({ product }) => {
  const allowedFields = [
    'Bredde',
    'Dybde',
    'Farge',
    'Hoyde',
    'Maal',
    'Modell',
    'Montering',
    'Vedlikehold',
    'Opprinnelsesland',
    'Tekstilogmateriale',
    'Anneninformasjon'
  ];
  let customFields = product.customFields.filter(item =>
    allowedFields.includes(item.key)
  );
  customFields = customFields.filter(item => {
    if (item.type === 'STRING' && item.stringValue === '0') {
      return null;
    } else {
      return item;
    }
  });

  customFields.push({
    key: 'ArticleNumber',
    type: 'STRING',
    title: 'Artikkel nummer',
    stringValue: product.articleNumber
  });

  /* Customfields contains Maal.
    1. Check if "Maal" exist in array.
    2. Hide "Bredde", "Hoyde" and "Dybde" if Maal exist.
    3. Change text from "Lengde i cm" to "Mål i cm".
    Note: This maybe should be updated from Jetshop Admin but it's a lot of products to update.
  */
  const targetKey = 'Maal';
  const excludeItems = ['Bredde', 'Hoyde', 'Dybde'];
  const targetTitle = 'Lengde i cm';
  const newTitle = 'Mål i cm';
  const customFieldsContainsMall = customFields.some(
    item => item.key === targetKey
  );

  const newCustomFields = customFieldsContainsMall
    ? customFields
        .map(item => {
          if (excludeItems.includes(item.key)) {
            return null;
          } else if (item.title === targetTitle) {
            return { ...item, title: newTitle };
          } else {
            return item;
          }
        })
        .filter(item => item !== null)
    : customFields;

  return (
    <Accordion single initialOpenIndexes={[1]}>
      {({ openIndexes, handleClick, AccordionContent }) => (
        <Fragment>
          <ProductSection>
            <SectionHeading onClick={() => handleClick(1)}>
              <span>{t('Specifications')}</span>
              <AccordionIndicator isOpen={openIndexes.includes(1)} />
            </SectionHeading>
            <AccordionContent isOpen={openIndexes.includes(1)}>
              <ProductSpecifications fields={newCustomFields} />
            </AccordionContent>
          </ProductSection>
        </Fragment>
      )}
    </Accordion>
  );
};

export default ProductInfoAccordion;
