import useHeaderHeight from '../../../hooks/useHeaderHeight'
import t from '@jetshop/intl'
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer'
import { styled } from 'linaria/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Caret } from '../../../../svg/angle-right.svg'
import { theme } from '../../../Theme'
import { MenuContentCategory } from './MenuContentCategory'
import {
  filterOutMenuContentFromRootCategories,
  findMenuContentCategoryId
} from '../../../ContentComponents/MenuContent'
import DynamicCategoryContentRenderer from '../../../ContentComponents/DynamicContentRenderer'
import { mobileMenuComponents } from '../../../ContentComponents/ContentComponents'

const Scroll = styled('div')`
  flex: 1 1 auto;
  position: relative;
`

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 99999;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`
const MenuWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  padding: 5px 20px 40px 20px;

  &.submenu {
    .categories {
      padding-bottom: 5px;
    }
    a,
    span {
      font-size: 14px;
      border: 0;
    }
    a {
      border-bottom: 1px solid ${theme.colors.beige};
    }
    button.backbutton {
      margin-bottom: 8px;
      span {
        font-size: 1rem;
        &.small {
          font-size: 11px;
          flex: none;
        }
      }
    }
  }

  button,
  a {
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-size: 1rem;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 14px 0;
    text-align: left;
    border-bottom: 1px solid ${theme.colors.beige};
    color: black;
    text-decoration: none;
    font-size: 1rem;
    letter-spacing: 0.5px;
    width: 100%;
    span {
      display: flex;
      align-items: center;
    }
    &.red {
      color: ${theme.colors.red};
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    svg {
      transform: rotate(-180deg);
      margin-right: 0;
    }
  }
`

const MenuContentWrapper = styled('div')`
  /* border-top: 1px solid ${theme.colors.beige}; */
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: scroll;
  > div {
    min-width: 200px;
    max-width: 200px;
  }
`

const MobileLinksWrapper = styled('div')`
  height: 100%;
  .dynamic-wrapper {
    height: 100%;
  }
`

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null)
  const [menuContentId, setMenuContentId] = useState(null)
  const backHandler = () => {
    setActiveId(null)
  }
  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null)
      }, 300)
    }
  }, [rest.isOpen, activeId])

  useEffect(() => {
    setMenuContentId(findMenuContentCategoryId(parent))
  }, [parent])

  const editedCategories = filterOutMenuContentFromRootCategories(categories)

  return (
    <>
      {active && (
        <>
          <MenuWrapper
            active={active}
            className={parent ? 'submenu' : 'toplevel'}
          >
            <div className="categories">
              {parent && (
                <button onClick={goBack} className="backbutton">
                  <span>{parent.name}</span>
                  <span className="small">
                    <Caret />
                    {parent.parent ? (
                      <>
                        {t('Back to ')}
                        {parent.parent.name}
                      </>
                    ) : (
                      t('Back')
                    )}
                  </span>
                </button>
              )}
              {parent && (
                <Link onClick={rest.close} to={parent.primaryRoute.path}>
                  <span>
                    <strong>
                      {t('Show all')} {parent.name}
                    </strong>
                  </span>
                </Link>
              )}
              {editedCategories.map(cat => {
                return (
                  <div key={`btn-${cat?.id}`}>
                    {cat?.hasSubcategories && cat?.subcategories ? (
                      <button onClick={() => setActiveId(cat?.id)}>
                        <span>{cat?.name}</span>
                        <Caret />
                      </button>
                    ) : (
                      <Link
                        onClick={rest.close}
                        to={
                          cat?.id === 158
                            ? '/inspirasjon-1'
                            : cat?.primaryRoute?.path
                        }
                        // key={`link-${cat.id}`}
                        className={cat?.name === 'På salg' ? 'red' : ''} // Is there a cleaner way to accomplish this, and make it dynamic?
                      >
                        <span>{cat?.name}</span>
                      </Link>
                    )}
                  </div>
                )
              })}
            </div>

            {!parent && (
              <MobileLinksWrapper>
                <DynamicCategoryContentRenderer
                  categoryId={393}
                  rendererComponents={mobileMenuComponents}
                />
              </MobileLinksWrapper>
            )}
            {/* 
            {menuContentId && (
              <MenuContentWrapper>
                <MenuContentCategory
                  menuContentId={menuContentId}
                  closeNav={rest.close}
                />
              </MenuContentWrapper>
            )} */}
            <MobileLinksWrapper>
              <MenuContentWrapper>
                <MenuContentCategory
                  menuContentId={393}
                  closeNav={rest.close}
                />
              </MenuContentWrapper>
            </MobileLinksWrapper>
          </MenuWrapper>

          {editedCategories?.map(cat =>
            cat?.hasSubcategories && cat?.subcategories ? (
              <MenuLevel
                key={`cat-${cat?.id}`}
                {...rest}
                parent={{ ...cat, parent: parent }}
                goBack={backHandler}
                categories={cat?.subcategories}
                active={cat?.id === activeId}
              />
            ) : null
          )}
        </>
      )}
    </>
  )
}

export const PositionedDrawer = ({ isOpen, size = 320, children, left }) => {
  const topPosition = useHeaderHeight()
  return (
    <NewDrawer
      topPosition={topPosition}
      isOpen={isOpen}
      size={size}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  )
}

export default function FlyoutMenu({ data, size = 320, left = false }) {
  if (!(data && data.categories)) return null
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <PositionedDrawer isOpen={drawer.isOpen} size={size} left={left}>
          <Scroll>
            <MenuLevel
              isOpen={drawer.isOpen}
              close={drawer.hideTarget}
              categories={data?.categories}
              active={true}
            />
          </Scroll>
        </PositionedDrawer>
      )}
    </DrawerTarget>
  )
}
