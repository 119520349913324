import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import CartQuery from '../../Cart/queries/cartQuery.gql';
import { StyledPrice } from '../../ui/StyledPrice';
import { Above } from '@jetshop/ui/Breakpoints';

const Container = styled('aside')`
  background-color: ${theme.colors.white};
  ${theme.below.md} {
    background-color: ${theme.colors.cafeNoir};
    * {
      color: white;
    }
  }

  ${theme.above.sm} {
    width: 360px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
`;

const ProductImageWrapper = styled('div')`
  width: 5rem;
  margin-right: 1rem;
  padding: 0 10px;
  background: #f5f5f5;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    mix-blend-mode: color;
  }
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: ${theme.space[2]};
  ${theme.below.md} {
    margin-right: ${theme.space[3]};
  }
  ${theme.below.sm} {
    margin-top: ${theme.space[1]};
    margin-bottom: ${theme.space[1]};
  }
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: black;

  ${theme.below.md} {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    > * {
      margin-right: 5px;
    }
  }
`;

const Header = styled('h3')`
  font-size: 14px;
  margin-bottom: ${theme.space[1]};
  ${theme.below.md} {
    margin-bottom: 0;
    font-size: 12px;
  }
`;

const ProductName = styled('p')`
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* flex-wrap: nowrap; */
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: 12px;
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  error
}) => {
  const pricing = selectedVariation || product;
  const image = selectedVariation?.images[0] || product.images[0] || '';
  return (
    <Container>
      <Above breakpoint="md">
        {matches => (
          <ProductCheckoutContainer>
            <Product>
              {matches && (
                <ProductImageWrapper>
                  <Image
                    src={image.url}
                    sizes={80}
                    aspect={'1:1'}
                    alt={product.name}
                    quality={80}
                  />
                </ProductImageWrapper>
              )}
              <ProductDetails>
                {error ? (
                  <Header>{t('Failed adding to bag')}</Header>
                ) : (
                  <Header>{t('Added to bag')}</Header>
                )}
                <ProductName>{product.name}</ProductName>
                {!error && (
                  <StyledPrice
                    price={pricing.price}
                    previousPrice={pricing.previousPrice}
                  />
                )}
                {error && (
                  <Error>
                    {t('An error occurred. Details:')}
                    <ul>
                      {error?.graphQLErrors &&
                      error?.graphQLErrors?.length > 0 ? (
                        error?.graphQLErrors.map(
                          ({ message, locations, path }) => (
                            <li key={message}>{t(message)}</li>
                          )
                        )
                      ) : (
                        <li>{t(error.message)}</li>
                      )}
                    </ul>
                  </Error>
                )}
              </ProductDetails>
            </Product>
          </ProductCheckoutContainer>
        )}
      </Above>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={CartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
