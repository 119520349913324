import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

const ImageBoxRowWrapper = styled(MaxWidth)`
  ${theme.above.lg} {
    &.reverse {
      .link-evaluator {
        flex-direction: row-reverse;
      }
    }
    .link-evaluator {
      display: flex;
    }
  }
`;

export const ImageBoxRow = ({
  children,
  link,
  fullWidth,
  desktopPadding,
  mobilePadding,
  reverse
}) => {
  return (
    <Above breakpoint="lg">
      {matches => (
        <ImageBoxRowWrapper
          className={reverse?.value && 'reverse'}
          style={{
            maxWidth: fullWidth?.value ? '100%' : '80rem',
            padding: `0 ${
              (matches ? desktopPadding?.value : mobilePadding?.value) ?? 0
            }px`
          }}
        >
          <LinkEvaluator link={link}>{children}</LinkEvaluator>
        </ImageBoxRowWrapper>
      )}
    </Above>
  );
};

const ImageWrapper = styled('div')`
  width: 100%;
`;

export const ImageBoxRowItem = ({
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  return (
    <ImageWrapper className="item-wrapper">
      <Above breakpoint="lg">
        {matches => (
          <Image
            sizes={imageSizes}
            src={matches ? desktopImage?.value : mobileImage?.value}
            aspect={
              matches ? desktopImageRatio?.value : mobileImageRatio?.value
            }
            cover
          />
        )}
      </Above>
    </ImageWrapper>
  );
};

const TextWrapper = styled('div')`
  min-height: 170px;
  width: 100%;
  margin: auto;
  color: ${props => props.color};
  text-align: center;
  background: ${props => props.backgroundcolor};
  padding: 40px;
  flex-direction: column !important;
  align-items: center;

  div {
    font-size: 16px;
    max-width: 380px;
    margin: auto;
    font-weight: 300;
    letter-spacing: 0.02em;
  }
  h2 {
    max-width: 410px;
    margin: auto;
    text-align: center;
    font-size: ${theme.fontSizes.tinyTitle};
    line-height: ${theme.lineHeight.tinyTitle};
    font-weight: 400;
    font-family: ${theme.fonts.title};
    margin-bottom: 10px;
  }

  ${theme.below.lg} {
    height: 200px;
    padding: 45px;

    div {
      font-size: 15px;
      line-height: 28px;
      max-width: 380px;
      margin: auto;
      font-weight: 300;
    }
    h2 {
      font-size: 26px;
      line-height: 32px;
      font-weight: 400;
      margin: 0px;
    }
  }
`;

export const ImageBoxRowTextItem = ({
  textColor,
  backgroundColor,
  title,
  text
}) => {
  return (
    <TextWrapper
      color={textColor?.value ?? '#000'}
      backgroundcolor={backgroundColor?.value ?? '#fff'}
      className="item-wrapper"
    >
      {title?.value && (
        <h2
          dangerouslySetInnerHTML={{
            __html: title?.value
          }}
        />
      )}
      {text?.value && (
        <div
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </TextWrapper>
  );
};
