import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { humanDistance } from '../../StoreLocator/StoreList';
import { cx } from 'linaria';
import { HCButton } from '../../ui/HCButton';
import StockStatusIndicator from '../StockStatus/StockStatusIndicator';
import { AccordionIndicator } from '../ProductInfoAccordion';
import { isEmpty } from '../../../utils/HelpFunctions';

const GetInStoreListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const GetInStoreList = ({
  stores,
  distances,
  getInStoreCallback,
  canExpand
}) => {
  stores =
    distances && !isEmpty(distances)
      ? getSortedStoresOnDistance(stores, distances)
      : stores;

  return (
    <GetInStoreListWrapper>
      {stores.map((store, index) => (
        <GetInStoreCard
          key={index + 'get-in-store-card'}
          store={store}
          canExpand={canExpand}
          distance={distances?.[store.id]}
          getInStoreCallback={e => {
            getInStoreCallback(e);
          }}
        />
      ))}
    </GetInStoreListWrapper>
  );
};

const getSortedStoresOnDistance = (stores, distances) => {
  return stores.sort((a, b) => {
    const idA = a.id.toString();
    const idB = b.id.toString();

    return distances[idA] - distances[idB];
  });
};

const GetInStoreCardWrapper = styled('div')`
  padding: 6px 1rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .accordion-icon {
    position: absolute;
    left: 0;
    top: 4px;
  }

  .no-expansion {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 32px;

    &.no-distance {
      min-height: 20px;
    }

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .store-name {
        font-style: normal;
        font-weight: 600;
        font-size: ${theme.fontSizes.tinyPrice};
        line-height: 19px;

        span {
          background: ${theme.colors.platinum};
        }
      }
    }

    .status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      p,
      span {
        font-size: 11px;
        line-height: 16px;
        margin-top: 0;
      }
    }
  }

  &:not(:first-child) {
    border-top: 1px solid #f5f6f8;
  }

  .content {
    max-height: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    p {
      font-size: 11px;
      line-height: 16px;
    }

    .open-hours {
      margin-top: 1rem;
    }
  }

  .expanded {
    max-height: 200px;
  }

  .hc-button-wrapper {
    width: 100%;
    height: 40px;
    margin-top: 1rem;
    margin-bottom: 10px;
  }
`;

export const GetInStoreCard = ({
  store,
  distance,
  getInStoreCallback,
  canExpand = true
}) => {
  const [expanded, setExpanded] = useState(false);
  const evaluatedDistance = humanDistance(distance);
  return (
    <GetInStoreCardWrapper
      onClick={() => {
        if (canExpand) {
          setExpanded(!expanded);
        }
      }}
    >
      <div
        className={cx(
          'store-name-row',
          'no-expansion',
          !evaluatedDistance && 'no-distance'
        )}
      >
        {canExpand && <AccordionIndicator isOpen={expanded} />}
        <div className="column">
          <h4
            className="store-name"
            dangerouslySetInnerHTML={{
              __html: store?.name
            }}
          />
        </div>

        <div className="status">
          <span>{evaluatedDistance}</span>
          <StockStatusIndicator
            noStatusModification={true}
            {...store?.stockstatus}
            tiny
          />
        </div>
      </div>
      <div className={cx('content', expanded && 'expanded')}>
        <div
          className="adress"
          dangerouslySetInnerHTML={{
            __html: store?.address1
          }}
        />
        <div
          className="open-hours"
          dangerouslySetInnerHTML={{
            __html: store?.openHours
          }}
        />
        <HCButton
          className={
            'DARK_BROWN ' +
            `${store?.stockstatus.status !== 'inStock' && 'disabled'}`
          }
          clickCallback={() => {
            getInStoreCallback(store);
          }}
        >
          KJøP
        </HCButton>
      </div>
    </GetInStoreCardWrapper>
  );
};
