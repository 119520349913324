import { forEachChild } from 'typescript';

interface ContentProp {
  name: string;
  value: any;
}

// const createPropObject = (prop : ContentProp) => {
//     console.log("createPropObject prop: ", prop)
//     let newprops = {}
//     newprops[prop.name] = prop
//     return  newprops;
// }

// TODO - COMMENTS - Check if works
export const injectContentPropToChild = (child, prop: ContentProp) => {
  let newprops = {};
  newprops[prop.name] = prop;

  return {
    ...child,
    props: { ...child?.props, ...newprops }
  };
};
// TODO - COMMENTS - Check if works
export const injectContentPropsToChild = (child, props: [ContentProp]) => {
  let newprops = {};
  props.forEach(prop => {
    newprops[prop.name] = prop;
  });

  return { ...child, props: { ...child.props, ...newprops } };
};
// TODO - COMMENTS - Check if works
export const injectContentPropToChildren = (children, prop: ContentProp) => {
  return children?.map(child => {
    return injectContentPropToChild(child, prop);
  });
};
// TODO - COMMENTS - Check if works
export const injectContentPropsToChildren = (
  children,
  props: [ContentProp]
) => {
  return children?.map(child => {
    return props?.map(prop => {
      return injectContentPropToChild(child, prop);
    });
  });
};
