module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#000000',
      main: '#000000',
      grey: '#878787',
      darkerGrey: '#767676',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#F2F0EB',
      darkbeige: '#C5BBAA',
      loadingBar: '#2f80ed',
      blue: '#146DE1',
      red: '#992932',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',
      brown: '#4E3525',
      pink: '#DCA093',
      darkVanlilla: '#C5BBAA',
      cafeNoir: '#4e3525',
      isabelline: '#F2F0EB',
      platinum: '#E8E5DE',
      green: '#718674'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontSizes: {
      tinyParagraph: '11px',
      tinyPrice: '12px',
      paragraph: '14px',
      infoTitle: '20px',
      tinyTitle: '28px',
      title: '40px',
      heroTitle: '75px'
    },
    lineHeight: {
      tinyParagraph: '13px',
      tinyPrice: '15px',
      paragraph: '16px',
      infoTitle: '24px',
      tinyTitle: '34px',
      title: '106%',
      heroTitle: '79px'
    },
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: 'Proxima Nova',
      title: 'Garamond',
      handwritten: 'Northwell'
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
