/**
 * Valuates if its prop from Content Editor Element
 * @param {Any} incomingProp - Property from Content Editor Element or passed inline
 * @return {Any} Prop value
 */
export const setVariableFromProp = incomingProp => {
  // Set prop no matter if its Content Editor prop ||  Element prop
  return (
    incomingProp?.value ??
    (typeof incomingProp === 'object' ? null : incomingProp)
  );
};

/**
 * Filters children by key
 * @param {object} children - Content Editor Element children
 * @param {string} filterText - Text used for checking against key
 * @param {bool} include - Should filtering include or exclude the keys starting with filterText
 * @return {array} Array containing filtered children
 */
export const filterChildrenByKey = (children, filterText, include) => {
  return include
    ? children?.filter(child => child?.key.startsWith(filterText))
    : children?.filter(child => !child?.key.startsWith(filterText));
};

/**
 * Removes tags <x> from string
 * @param {string} textString - Text that will be cleansed from tags
 * @return {string} String without tags
 */
export const removeTags = textString => {
  if (!textString) {
    return '';
  }
  return textString.replace(/(<([^>]+)>)/gi, '');
};

/**
 * Validate if text can be parsed to an Int
 * @param {string} text - Text to validate to number
 * @return {int} correct validated number
 */
export const validateNumber = text => {
  let returnText = parseInt(text);
  if (isNaN(returnText)) {
    returnText = null;
  }
  return returnText;
};

/**
 * Get lorem ipsum text
 * @param {int} length - To shorten the original text use length lower than full text
 * @return {string} lorem ipsum text
 */
export const loremIpsum = (length = null) => {
  const originalText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

  return length ? originalText.substring(0, length) : originalText;
};

/**
 * Get 0-9 numbers spelled in text
 * @param {int} number - Number translated to text string (0-9)
 * @return {string} number spelled in text
 */
export const numberToSpelledText = number => {
  switch (number) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
    case 9:
      return 'nine';

    default:
      return 'number-not-found';
  }
};

/**
 * Creates enum (freeze object)
 * @param {array} values - Array to create enums from
 * @return {object} Object containing list values
 */
export const createEnum = values => {
  const enumObject = {};
  for (const val of values) {
    enumObject[val.toUpperCase()] = val;
  }
  return Object.freeze(enumObject);
};

/**
 * Inserts props to item
 * @param {object} item - Item to add props to
 * @param {object} extraProps - New props (prop {name: string, value: any}
 *
 * @return A new item with the extraprops property added to its properties array
 */
export const insertPropToComponent = (item, extraProps) => {
  let newItem = {
    ...item,
    properties: [...item.properties, extraProps]
  };
  return newItem;
};
/**
 * Inserts props to all root components
 * @param {array} items - Items to add props to
 * @param {object} extraProps - New props (prop {name: string, value: any}
 *
 * @return List of items with the extraprops property added to its properties array
 */
export const insertPropToRootComponents = (items, extraProps) => {
  const newItems = items?.map(root => {
    return insertPropToComponent(root, extraProps);
  });
  return newItems;
};
/**
 * Inserts props to all components children
 * @param {array} items - Root items to add props to there children
 * @param {object} extraProps - New props (prop {name: string, value: any}
 *
 * @return List of items with the extraprops property added to its childrens properties array
 */
export const insertPropToComponentsChildren = (items, extraProps) => {
  const roots = items?.map(root => {
    const newChildren = root?.children?.map(item => {
      return insertPropToComponent(item, extraProps);
    });
    return { ...root, children: newChildren };
  });
  return roots;
};

/**
 * Is object Empty
 * @param {object} item - Item to check if it's empty or not.
 */
export const isEmpty = obj => {
  return obj == null || Object.keys(obj).length === 0;
};
