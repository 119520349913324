import React from 'react';
import ReactModal from 'react-modal';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { theme } from '../Theme';

const modalStyle = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  :focus {
    outline: none;
  }

  .modal-inside {
    width: fit-content;
    max-width: 780px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 85vh;
    min-height: 300px;
    height: fit-content;
    overflow-y: scroll;
    background: white;

    ${theme.above.md} {
      min-width: 500px;
    }

    svg.close-icon {
      cursor: pointer;
      z-index: 1;
      position: absolute;
      top: 33px;
      right: 33px;
      width: 20px;
      height: 20px;
      color: black;
      use,
      g,
      path {
        fill: black;
      }
    }
  }
`;

const curtainStyle = css`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: all 2s ease-in-out;
  transform: translateX(-50%);
  min-width: 500px;

  ${theme.below.md} {
    width: 100%;
    min-width: 100%;
  }

  :focus {
    outline: none;
  }

  .modal-inside {
    transition: all 2s ease-in-out;
    max-width: 780px;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 50vh;
    max-height: 85vh;
    background: white;
    overflow-y: scroll;

    ${theme.below.md} {
      width: 100%;
    }

    &.up {
      max-height: 85vh;
    }

    .inner {
      ${theme.above.md} {
        width: 500px;
      }
    }

    .close-icon {
      cursor: pointer;
      z-index: 1;
      position: fixed;
      top: 8px;
      right: 8px;
      width: 33px;
      height: 33px;
      padding: 9px 9px 9px 10px;
      border-radius: 50%;
      background: #fff;
      color: black;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      use,
      g,
      path {
        fill: black;
      }
    }
  }
`;

const ModalInside = styled('div')``;

const Inner = styled('div')`
  padding: 30px;
  display: block;
  max-height: 100%;
  ${theme.below.lg} {
    padding: 30px 15px;
  }
`;

ReactModal.setAppElement('#root');

export const PopupContent = ({ open, setOpen, type = 'popup', children }) => {
  const handleCloseClick = () => {
    setOpen(false);
  };

  const isCurtain = type === 'curtain';

  return (
    <ReactModal
      isOpen={open}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleCloseClick}
      className={cx(isCurtain ? curtainStyle : modalStyle)}
    >
      <ModalInside className={cx('modal-inside', isCurtain && 'up')}>
        <Cross
          className="close-icon"
          onClick={handleCloseClick}
          data-testid="popup-close"
        />
        <Inner className="inner">{children}</Inner>
      </ModalInside>
    </ReactModal>
  );
};
