import React from 'react';
import { styled } from 'linaria/react';
import DynamicCategoryContentRenderer from '../../../ContentComponents/DynamicContentRenderer';
import { menuContentComponents } from '../../../ContentComponents/ContentComponents';

const ContentWrapper = styled('div')`
  .menu-content-wrapper {
    margin-bottom: 0px !important;
  }
`;

export function MenuContentCategory({ menuContentId, closeNav }) {
  return (
    <ContentWrapper>
      <DynamicCategoryContentRenderer
        categoryId={menuContentId}
        rendererComponents={menuContentComponents}
        extraRootProps={null}
        extraItemProps={{ name: 'closeNav', value: closeNav }}
      />
    </ContentWrapper>
  );
}
