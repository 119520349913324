import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import React, { useContext, useState } from 'react';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { theme } from '../../Theme';
import IconRow from './IconRow';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import SearchBar from '../../Findify/Search/SearchBar';

const MobileHeaderWrapper = styled('div')`
  background: ${theme.colors.beige};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 10px;
  height: 60px;
  width: 100%;
`;
const LogoLink = styled(Link)`
  padding: 10px;
`;

export default function MobileHeader() {
  const [searchOpen, setSearchOpen] = useState(false);
  const [favouritesFlyoutClose, setFavouritesFlyoutClose] = useState(false);
  const [cartFlyoutClose, setCartFlyoutClose] = useState(false);
  const { hideTarget } = useContext(ModalContext);
  return (
    <>
      <MobileHeaderWrapper id="site-header">
        <LogoLink
          onClick={() => {
            // Closes flyout if open
            hideTarget();
          }}
          to="/"
        >
          <Logo />
        </LogoLink>
        <IconRow
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          favouritesFlyoutClose={favouritesFlyoutClose}
          setFavouritesFlyoutClose={setFavouritesFlyoutClose}
          cartFlyoutClose={cartFlyoutClose}
          setCartFlyoutClose={setCartFlyoutClose}
        />
        {searchOpen && <SearchBar />}
      </MobileHeaderWrapper>
    </>
  );
}
