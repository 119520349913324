import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import ChannelContext from '@jetshop/core/components/ChannelContext'
import { useHistory } from 'react-router'
import { FindifyTrackingContext } from './Tracking'
import { useFlightFunctions } from './useFlightFunctions'

// LOADER FOR THE DIFFERENT CHANNELS
export const FindifyLoader = () => {
  const selectedChannel = useContext(ChannelContext)?.selectedChannel
  const channelId = selectedChannel.id

  // MODIFY THIS TO REFLECT THE CHANNEL SETUP IN THE CURRENT SHOP
  const findifyAccounts = {
    channel_1: 'homeandcottage'
  }
  let accountId = findifyAccounts['channel_' + channelId]

  // STAGING ACCOUNT TO BE USED IN STAGING ENVIRONMENTS
  const findifyStagingAccount = 'homeandcottage';

  // // USE STAGING ACCOUNT WHEN IN STAGING
  // if (typeof window !== 'undefined' && window.location.host.match(/\.dev\.jetshop/i)) {
  //   accountId = findifyStagingAccount;
  // }

  return selectedChannel ? (
    <Helmet>
      <script
        id="findify-script"
        src={`//assets.findify.io/${accountId}.no.min.js`}
        async="true"
        defer="true"
        type="text/javascript"
      />
    </Helmet>
  ) : null
}

// HOW TO TRANSFORM STANDARD ARTICLE NUMBER INTO BASE ITEM ID
// SOMETIMES IT IS A SUBSET OF THE ARTICLE NUMBER
// (THIS CAN VARY BETWEEN STORES, FIND OUT WHAT IS NEEDED)
export const getBaseItemId = articleNumber => {
  // NO TRANSFORMATION AS STANDARD
  const itemId = articleNumber
  return itemId
}

const FindifyProvider = ({ children }) => {
  useFlightFunctions()
  const [lastTrackedPath, setLastTrackedPath] = useState('')
  const history = useHistory()

  const goToRoute = route => {
    history.push(route)
  }

  useEffect(() => {
    window.goToRoute = goToRoute
  })

  return (
    <FindifyTrackingContext.Provider
      value={{ lastTrackedPath, setLastTrackedPath }}
    >
      <FindifyLoader />
      {children}
    </FindifyTrackingContext.Provider>
  )
}

export default FindifyProvider
