import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import MaxWidth from '../Layout/MaxWidth';
import {
  filterChildrenByKey,
  numberToSpelledText,
  validateNumber
} from '../../utils/HelpFunctions';
import { Above } from '@jetshop/ui/Breakpoints';
import { injectContentPropsToChild } from '../../utils/ContentProp';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { HCButton } from '../ui/HCButton';
import { StyledPrice } from '../ui/StyledPrice';
import { ContentPlacer } from './ContentPlacer';

const InspirationGridScroller = styled('section')`
  ${theme.below.lg} {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const InspirationGridWrapper = styled(MaxWidth)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(11, 5vw);
  grid-column-gap: ${props => props.gap}px;
  grid-row-gap: ${props => props.gap}px;

  ${theme.above.xl} {
    grid-template-rows: repeat(11, 65px);
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(4, 85vw);
    grid-template-rows: repeat(2, 60vw);
    width: 450vw;
    padding: 0;
  }

  .div-one {
    grid-area: 1 / 1 / 8 / 2;
    ${theme.below.lg} {
      grid-area: 1 / 1 / 3 / 2;
    }
  }
  .div-two {
    grid-area: 1 / 2 / 4 / 3;
    ${theme.below.lg} {
      grid-area: 1 / 2 / 2 / 3;
    }
  }
  .div-three {
    grid-area: 1 / 3 / 7 / 4;
    ${theme.below.lg} {
      grid-area: 2 / 2 / 3 / 3;
    }
  }
  .div-four {
    grid-area: 4 / 2 / 10 / 3;
    ${theme.below.lg} {
      grid-area: 1 / 3 / 2 / 4;
    }
  }
  .div-five {
    grid-area: 7 / 3 / 10 / 4;
    ${theme.below.lg} {
      grid-area: 2 / 3 / 3 / 4;
    }
  }
  .div-six {
    grid-area: 8 / 1 / 11 / 2;
    ${theme.below.lg} {
      grid-area: 1 / 4 / 2 / 5;
    }
  }

  .div-seven {
    grid-area: 10 / 3 / 12 / 4;
    ${theme.below.lg} {
      grid-area: 2 / 4 / 3 / 5;
    }
  }
`;

export const InspirationGrid = ({ desktopGap, mobileGap, children }) => {
  const desktopGapSize = validateNumber(desktopGap?.value) ?? 20;
  const mobildeGapSize = validateNumber(mobileGap?.value) ?? 10;

  const titleAndTextChildren = filterChildrenByKey(
    children,
    'titleAndLink',
    true
  );

  const inspirationGridItemChildren = filterChildrenByKey(
    children,
    'inspirationGridItem',
    true
  );

  const inspirationGridInfoChildren = filterChildrenByKey(
    children,
    'inspirationGridInfo',
    true
  );

  const injectedinspirationGridItemChildren = inspirationGridItemChildren?.map(
    (child, index) => {
      return injectContentPropsToChild(child, [
        {
          name: 'indexClassName',
          value: index + 1
        }
      ]);
    }
  );

  const injectedinspirationGridInfoChildren = inspirationGridInfoChildren?.map(
    child => {
      return injectContentPropsToChild(child, [
        {
          name: 'indexClassName',
          value: 7
        }
      ]);
    }
  );

  return (
    <Above breakpoint="lg">
      {matches => (
        <>
          {titleAndTextChildren}
          <InspirationGridScroller>
            <InspirationGridWrapper
              gap={matches ? desktopGapSize : mobildeGapSize}
            >
              {injectedinspirationGridItemChildren}
              {injectedinspirationGridInfoChildren}
            </InspirationGridWrapper>
          </InspirationGridScroller>
        </>
      )}
    </Above>
  );
};

const InspirationGridItemWrapper = styled('div')`
  position: relative;

  .product-info {
    color: ${props => props.color};
    height: fit-content;
    width: fit-content;
  }
`;

export const InspirationGridItem = ({
  desktopImage,
  mobileImage,
  link,
  productItem,
  color,
  verticalPosition,
  horizontalPosition,
  indexClassName
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  return (
    <InspirationGridItemWrapper
      color={color?.value ?? '#000'}
      className={cx(
        'div-' + numberToSpelledText(indexClassName?.value) // fix for Safari
      )}
    >
      <LinkEvaluator link={link}>
        <Above breakpoint="lg">
          {matches => (
            <>
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage?.value}
                fillAvailableSpace
              />
              {productItem?.value && (
                <ContentPlacer
                  horizontalPosition={
                    horizontalPosition?.value
                      ? horizontalPosition
                      : { value: 'CENTER' }
                  }
                  verticalPosition={
                    verticalPosition?.value
                      ? verticalPosition
                      : { value: 'MIDDLE' }
                  }
                >
                  <div className="product-info">
                    <h3>{productItem?.value?.name}</h3>
                    <StyledPrice
                      price={productItem?.value?.price}
                      previousPrice={productItem?.value?.previousPrice}
                    />
                  </div>
                </ContentPlacer>
              )}
            </>
          )}
        </Above>
      </LinkEvaluator>
    </InspirationGridItemWrapper>
  );
};

const InspirationGridInfoWrapper = styled('div')`
  position: relative;

  .info-wrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${props => props.color};
    padding: 0 15px;
    h3 {
      font-family: ${theme.fonts.title};
      font-weight: ${theme.fontWeights.regular};
      font-size: ${theme.fontSizes.infoTitle};
      line-height: ${theme.lineHeight.infoTitle};
      margin-bottom: 10px;
    }

    p {
      font-size: ${theme.fontSizes.tinyParagraph};
      line-height: ${theme.lineHeight.tinyParagraph};
    }
  }
`;

export const InspirationGridInfo = ({
  textColor,
  title,
  text,
  buttonText,
  buttonlink,
  buttonTheme,
  indexClassName
}) => {
  return (
    <InspirationGridInfoWrapper
      color={textColor?.value ?? '#000'}
      className={cx(
        'div-' + numberToSpelledText(indexClassName?.value) // fix for Safari
      )}
    >
      <LinkEvaluator link={buttonlink}>
        <div className="info-wrapper">
          <div>
            {title?.value && <h3>{title?.value}</h3>}
            {text?.value && <p>{text?.value}</p>}
          </div>

          {buttonText?.value && (
            <HCButton className={cx(buttonTheme?.value ?? 'BEIGE')}>
              {buttonText?.value}
            </HCButton>
          )}
        </div>
      </LinkEvaluator>
    </InspirationGridInfoWrapper>
  );
};
