import useGeolocation from '@jetshop/core/hooks/useGeolocation';
import t from '@jetshop/intl';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import { ReactComponent as Pin } from '../../svg/Pin.svg';
import { ReactComponent as Satelite } from '../../svg/Satelite.svg';
import { theme } from '../Theme';

const UserLocationContainer = styled('li')`
  position: relative;
  width: 100%;
  background: #fff;
  padding: 5px 0;

  > div {
    width: 100%;
    max-width: 260px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const TextButton = styled('button')`
  text-transform: uppercase;
  font-size: 11px;
  color: black;
  letter-spacing: 1px;
  font-weight: bold;
  opacity: 0.8;
  background: none;
  border: 0;
  appearance: none;
  &:hover {
    opacity: 1;
  }
  svg {
    height: 13px;
    margin-right: 5px;
    margin-top: -1px;
  }
  .searching-position {
    color: ${theme.colors.darkerGrey};

    svg {
      /* animation-name: rotate;
      animation-duration: 1s;
      animation-direction: alternate; */
      /* animation: loadingC 0.6s 0.1s linear infinite; */
      animation: rotation 2.5s infinite linear;
    }
  }
  @keyframes rotation {
    0% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(20deg);
    }
    100% {
      transform: rotate(-20deg);
    }
  }
`;

const UserLocation = ({ setUserLocation }) => {
  const [useLocation, setUseLocation] = useState(false);
  const { getPosition, isAvailable, position } = useGeolocation({
    onSuccess: ({ coords: { latitude, longitude } }) =>
      setUserLocation({ latitude, longitude }),
    onError: err => console.log(err)
  });

  if (!isAvailable || position) return null;

  return (
    <UserLocationContainer className="user-location-container">
      <div>
        <TextButton
          onClick={() => {
            if (!useLocation) {
              getPosition();
              setUseLocation(true);
            }
          }}
        >
          {useLocation ? (
            <div className="searching-position">
              <Satelite />
              {'Oppdaterer posisjonen min'}
            </div>
          ) : (
            <>
              <Pin />
              {t('Search based on my location')}
            </>
          )}
        </TextButton>
      </div>
    </UserLocationContainer>
  );
};

export default UserLocation;
