import { useLocation } from 'react-router-dom';
import React from 'react';
function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    let paramArray = {};
    for (const [key, value] of searchParams) {
      paramArray[key] = value;
    }
    return paramArray;
  }, [search]);
}

export default useQueryParams;
