import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { styled } from 'linaria/react';
import React from 'react';
import useHeaderHeight from '../hooks/useHeaderHeight';
import { theme } from '../Theme';
import { baseStyles } from '../ui/Button';
import { DynamicUsps } from '../ui/DynamicUsps';
import CartItem from './CartItem';
import cartQuery from './queries/cartQuery.gql';
import FreeShipping from './FreeShipping';
import useQueryParams from '../useQueryParams';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useNotification } from '@jetshop/core/components/Notifications';

const DrawerWrapper = styled('div')`
  > div {
    background: ${theme.colors.beige};
    top: ${props => props.topPosition}px;
  }
`;

const Flyout = styled('div')`
  background: ${theme.colors.beige};
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }
`;

const CartItems = styled('section')`
  background: white;
  padding: 20px;
`;

const Summary = styled('section')`
  padding: 30px 20px;

  .cart-total {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 14px;
    label {
      margin-right: 3px;
    }
  }
`;

const Checkout = styled.a`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`;
const KeepShopping = styled.button`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background: ${theme.colors.darkbeige};
  color: white;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  border: 0;
`;

export const CartUsp = styled('div')`
  padding-top: 9px;
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const track = useTracker();

  if (items?.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em', background: 'white' }}>
          <h2 style={{ marginBottom: '1em' }}>{t('No items in cart.')}</h2>
        </div>
        <Summary>
          <KeepShopping onClick={modal.hideTarget}>
            {t('Continue Shopping')}
          </KeepShopping>
          <CartUsp>
            <DynamicUsps id={392} />
          </CartUsp>
        </Summary>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} allItems={items} />
        ))}
      </CartItems>

      <Summary>
        <FreeShipping
          className="shipping"
          cartTotal={result.data.cart.productTotal}
        />
        <div className="cart-total">
          <label>{t('Totalt')}:</label>
          <Price price={result.data.cart.productTotal} />
        </div>

        {checkoutUrl && (
          <Checkout
            data-testid="checkout-button"
            href={checkoutUrl}
            onClick={event => {
              event.preventDefault();
              track(
                trackCartCheckoutEvent({
                  cart: result.data.cart,
                  callback: () => {
                    window.location = checkoutUrl;
                  }
                })
              );
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
        <KeepShopping onClick={modal.hideTarget}>
          {t('Continue Shopping')}
        </KeepShopping>
        <CartUsp>
          <DynamicUsps id={392} />
        </CartUsp>
      </Summary>
    </Flyout>
  );
};

const RedirectToast = styled('div')`
  background: white;
  color: ${theme.colors.black};
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const CheckoutRedirect = ({ result }) => {
  const url = result?.data?.cart?.externalCheckoutUrl;
  const params = useQueryParams();
  const [trigger] = useNotification();
  const { loggedIn } = useAuth();

  if (loggedIn && params && params?.signinRedirect?.includes('checkout')) {
    if (typeof window !== 'undefined') {
      trigger(
        <RedirectToast>{t('Redirecting back to checkout')}</RedirectToast>
      );
      setTimeout(() => {
        window.location.href = url;
      }, 2000);
    }
  }
  return null;
};

const CartFlyout = props => {
  const topPosition = useHeaderHeight();
  return (
    <CartProvider query={cartQuery}>
      {result =>
        result.data && result.data.cart ? (
          <>
            <CheckoutRedirect result={result} />
            <DrawerTarget id="cart-drawer">
              {drawer => (
                <DrawerWrapper topPosition={topPosition}>
                  <Drawer isOpen={drawer.isOpen} right>
                    <CartFlyoutView modal={drawer} result={result} {...props} />
                  </Drawer>
                </DrawerWrapper>
              )}
            </DrawerTarget>
          </>
        ) : null
      }
    </CartProvider>
  );
};

export default CartFlyout;
