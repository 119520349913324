import { Helmet } from 'react-helmet-async';
import React from 'react';
import { useDarkMode } from '../hooks/useDarkMode';

/**
 * The FaviconSelector function is a React component that renders the appropriate
 * favicon based on whether or not dark mode is enabled. It takes in two parameters:
 * lightmodeFolderPath and darkmodeFolderPath, which are both strings that specify the path to the folder containing
 * all of our favicons. The FaviconSelector function uses these paths to determine which icon should be rendered,
 * depending on whether or not dark mode is enabled.
 *
 * @param {string} lightmodeFolderPath the path to the lightmode folder
 * @param {string} darkmodeFolderPath the path to the darkmode folder
 *
 * @return A helmet component that contains the following tags:
 *
 * @docauthor Anders Zetterström
 */
export const FaviconSelector = ({
  lightFaviconFolderPath,
  darkFaviconFolderPath
}) => {
  const { darkMode, faviconFolderPath } = useDarkMode(
    lightFaviconFolderPath,
    darkFaviconFolderPath
  );

  const themeColor = darkMode ? '#c5bbaa' : '#4e3525';

  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${faviconFolderPath}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${faviconFolderPath}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${faviconFolderPath}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`${faviconFolderPath}/site.webmanifest`} />
      <link
        rel="mask-icon"
        href={`${faviconFolderPath}/safari-pinned-tab.svg`}
        color={themeColor}
      />
      <link rel="shortcut icon" href={`${faviconFolderPath}/favicon.ico`} />
      <meta name="apple-mobile-web-app-title" content="Home &amp; Cottage" />
      <meta name="application-name" content="Home &amp; Cottage" />
      <meta name="msapplication-TileColor" content={themeColor} />
      <meta name="theme-color" content={themeColor} />
    </Helmet>
  );
};
