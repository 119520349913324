import cartQuery from '../Cart/queries/cartQuery.gql';
import addMultipleToCartMutation from '../Cart/queries/addMultipleToCart.gql';
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import t from '@jetshop/intl';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';
import ProductToastWrapper from '../ProductPage/AddToCart/ProductToast';
import { addToCart } from '../Cart/queries/addToCart.gql';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import { HCButton } from './HCButton';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { PopupContent } from './PopupContent';
import { GetInStore } from '../ProductPage/GetInStore/GetInStore';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import {
  dateParse,
  useModifiedStockStatus
} from '../ProductPage/StockStatus/useModifiedStockStatus';
import { v4 as uuidv4 } from 'uuid';

const AddToCartQtyButtonContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .hc-button-wrapper {
    width: 100% !important;
    margin-left: 5px;
  }

  .qty-adjuster {
    flex: none;
  }
`;

// TODO - ADD VALIDATION
export const AddToCartQtyButton = ({
  product,
  productPage,
  outOfStockNotify,
  activeLeg,
  disableBuyButton
}) => {
  const [qty, setQty] = useState(1);
  const { cartId, setCartId } = useContext(CartIdContext);
  const stockStatus = useStockStatus(product);
  const articleNumber =
    product?.variant?.articleNumber || product?.articleNumber;
  const price = {
    price: product?.variant?.price || product?.price,
    previousPrice: product?.variant?.previousPrice || product?.previousPrice
  };

  const getInStore = product?.customFields?.find(
    customField => customField?.key === 'kjopoghent'
  )?.boolValue;

  const { modifiedStockStatus } = useModifiedStockStatus(
    stockStatus?.text,
    stockStatus?.status
  );

  const { productCommentDate } = dateParse(stockStatus?.text);

  const [comment, setComment] = useState(null);
  const [getInStoreOpen, setGetInStoreOpen] = useState(false);

  const [trigger] = useNotification();
  const relation_id = uuidv4();
  let productsWithLegs = [
    {
      name: product.name,
      articleNumber: articleNumber,
      qty: qty,
      comments: [{ name: 'relation', value: relation_id }]
    }
  ];
  if (activeLeg) {
    productsWithLegs.push({
      name: activeLeg.name,
      articleNumber: activeLeg.articleNumber,
      qty: qty,
      comments: [{ name: 'relation', value: relation_id }]
    });
  }
  const [addMultiple] = useAddMultipleToCart(
    productsWithLegs,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: data => {
        trigger(
          <ProductToastWrapper
            selectedVariation={product?.variant}
            product={product}
            quantity={qty}
            price={price}
          />,
          { type: 'add-to-cart' }
        );
        addToCartSuccess({ cartId, setCartId })({ data });
      }
    }
  );

  const [add] = useMutation(addToCart, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: qty,
        comments: comment
      }
    },
    onCompleted: data => {
      trigger(
        <ProductToastWrapper
          selectedVariation={product?.variant}
          product={product}
          quantity={qty}
          price={price}
        />,
        { type: 'add-to-cart' }
      );
      addToCartSuccess({ cartId, setCartId })({ data });
    }
  });

  const handleAddToCart = product => {
    if (
      stockStatus?.text?.startsWith('Kommer') ||
      stockStatus?.text?.startsWith('Ca')
    )
      setComment([
        {
          name: 'Leveringsdato',
          value: productCommentDate
        }
      ]);
    if (activeLeg) {
      setTimeout(() => {
        addMultiple();
      }, 200);
    } else {
      setTimeout(() => {
        add();
      }, 200);
    }
  };

  const handleGetInStoreCallback = store => {
    setComment([
      { name: 'Klikk og hent', value: store?.name },
      { name: 'ID', value: store?.externalId }
    ]);
    setTimeout(() => {
      add();
      setGetInStoreOpen(false);
    }, 200);
  };

  return (
    <>
      <AddToCartQtyButtonContainer>
        <QtyAdjuster qty={qty} setQty={setQty}></QtyAdjuster>
        {productPage ? (
          <div className="button-wrapper">
            {disableBuyButton ? (
              <div style={{ opacity: '0.5', width: '100%' }}>
                <HCButton
                  testid="add-to-cart"
                  className={cx(
                    'GREEN',
                    'tiny',
                    modifiedStockStatus === 'outOfStock' && 'disabled'
                  )}
                >
                  Velg bensett
                </HCButton>
              </div>
            ) : (
              <>
                {!outOfStockNotify && (
                  <HCButton
                    testid="add-to-cart"
                    className={cx(
                      'GREEN',
                      'tiny',
                      modifiedStockStatus === 'outOfStock' && 'disabled'
                    )}
                    clickCallback={handleAddToCart}
                    disabled={disableBuyButton}
                  >
                    {'LEGG I HANDLEKURV'}
                  </HCButton>
                )}
              </>
            )}

            {getInStore && (
              <HCButton
                className={'DARK_BROWN tiny'}
                clickCallback={() => {
                  setGetInStoreOpen(true);
                }}
              >
                {t('click & pickup')}
              </HCButton>
            )}
          </div>
        ) : (
          <HCButton
            className={'DARK_BROWN tiny'}
            clickCallback={handleAddToCart}
          >
            {t('Add to cart')}
          </HCButton>
        )}
      </AddToCartQtyButtonContainer>

      {getInStoreOpen && (
        <PopupContent
          type={'curtain'}
          open={getInStoreOpen}
          setOpen={setGetInStoreOpen}
        >
          <GetInStore
            title={t('click & pickup')}
            getInStoreCallback={handleGetInStoreCallback}
            product={product}
          />
        </PopupContent>
      )}
    </>
  );
};

const QtyAdjusterContainer = styled('div')`
  > * {
    margin-right: 2px;
  }

  button {
    width: 30px;
    height: 30px;
    background: #f9f9f9;
    font-size: ${theme.fontSizes.tinyParagraph};
  }

  input {
    border: none;
    width: 30px;
    height: 30px;
    background: #f9f9f9;
    text-align: center;
    font-size: ${theme.fontSizes.tinyParagraph};

    /* --> Remove standard buttons from input <-- */
    /* Chrome, Safari, Edge, Opera */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    [type='number'] {
      -moz-appearance: textfield;
    }

    :focus {
      outline: 1px solid ${theme.colors.darkVanlilla};
    }
  }
`;

export const QtyAdjuster = ({ qty = 1, setQty }) => {
  return (
    <QtyAdjusterContainer className="qty-adjuster">
      <button
        disabled={qty < 2}
        onClick={e => {
          e.preventDefault();
          setQty(qty - 1);
        }}
      >
        -
      </button>
      <input
        value={qty}
        type={'number'}
        onChange={e => {
          const value = e?.currentTarget?.value;
          const newInt = parseInt(value);
          const notNumber = isNaN(newInt);

          if (!notNumber) {
            setQty(newInt);
          }
        }}
      />
      <button
        onClick={e => {
          e.preventDefault();
          setQty(qty + 1);
        }}
      >
        +
      </button>
    </QtyAdjusterContainer>
  );
};
