import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import IconRow from './IconRow';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { theme } from '../../Theme';
import ModalContext from '@jetshop/ui/Modal/ModalContext';

const DesktopHeaderWrapper = styled('div')`
  background: ${theme.colors.beige};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 85px;
  position: sticky;
  top: 0;
  z-index: 3;
`;
const HeaderColumn = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  &.center {
    flex: none;
    flex-grow: 1;
  }
  &.right {
    justify-content: flex-end;
  }
`;
const LogoLink = styled(Link)`
  padding: 10px;
  svg {
    width: 170px;
    height: 31px;
  }
`;

export default function DesktopHeader(result) {
  const { hideTarget } = useContext(ModalContext);
  const [favouritesFlyoutClose, setFavouritesFlyoutClose] = useState(false);
  const [cartFlyoutClose, setCartFlyoutClose] = useState(false);

  return (
    <>
      <DesktopHeaderWrapper id="site-header">
        <HeaderColumn className="left">
          <LogoLink
            onClick={() => {
              // Closes flyout if open
              hideTarget();
            }}
            to="/"
          >
            <Logo />
          </LogoLink>
        </HeaderColumn>
        <HeaderColumn className="center">
          <CategoryMenu data={result.categories.data} />
        </HeaderColumn>
        <HeaderColumn className="right">
          <IconRow
            favouritesFlyoutClose={favouritesFlyoutClose}
            setFavouritesFlyoutClose={setFavouritesFlyoutClose}
            cartFlyoutClose={cartFlyoutClose}
            setCartFlyoutClose={setCartFlyoutClose}
          />
        </HeaderColumn>
      </DesktopHeaderWrapper>
    </>
  );
}
