import Image from '@jetshop/ui/Image/Image';
import React from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { theme } from '../Theme';

export const CategoryHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${theme.below.xl} {
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
`;

const CategoryImageWrapper = styled('div')`
  height: 400px;
  width: 100%;
  ::after {
    display: block;
    position: relative;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.195) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    content: '';
    margin-top: -320px;
    height: 320px;
  }
  ${theme.below.md} {
    height: 217px;
    ::after {
      margin-top: -217px;
      height: 217px;
    }
  }
  [data-flight-image-children] {
    z-index: 1 !important;
  }
`;

export const NoImageWrapper = styled('div')`
  padding: 10px;
  margin-top: 20px;

  h1 {
    color: ${theme.colors.brown};
  }
`;

export const CategoryName = styled('h1')`
  font-weight: 300;
  line-height: normal;
  font-size: 60px;
  line-height: 63px;
  margin-bottom: 10px;
  letter-spacing: 0.02em;
  color: ${theme.colors.beige};
  font-family: ${theme.fonts.title};

  ${theme.below.lg} {
    text-align: center;
  }
`;

const CategoryContent = styled('div')`
  max-width: 540px;
  font-weight: 300;
  line-height: 26px;
  font-size: 18px;
`;

const headerImageStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = ({ category, content }) => (
  <Inner>
    <CategoryName data-testid="page-header">{category.name}</CategoryName>
    <CategoryContent
      data-testid="category-description"
      dangerouslySetInnerHTML={{
        __html: content?.value || category.content
      }}
    />
  </Inner>
);

const CategoryHeader = ({
  category,
  parents,
  imageSrc,
  breadcrumbs,
  content
}) => {
  const breadcrumbProps = {
    breadcrumbs,
    parents
  };
  const isImageBackground =
    imageSrc || (category?.images && category?.images?.length > 0);

  return (
    <>
      {/* <StyledBreadcrumbs parents={parents} breadcrumbs={breadcrumbs} /> */}
      <CategoryHeaderWrapper>
        {isImageBackground ? (
          <CategoryImageWrapper>
            <Image
              fillAvailableSpace
              src={imageSrc?.value || category.images}
              cover
              quality={80}
              className={headerImageStyles}
              critical={true}
              sizes={[1, 1, 1, 1, 1216]}
            >
              <Content category={category} content={content} />
            </Image>
          </CategoryImageWrapper>
        ) : (
          <NoImageWrapper>
            <Content
              category={category}
              breadcrumbProps={breadcrumbProps}
              content={content}
            />
          </NoImageWrapper>
        )}
      </CategoryHeaderWrapper>
    </>
  );
};

export default CategoryHeader;
