import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React, { useState, useContext } from 'react';
import { theme } from '../Theme';
import { Favourite } from '../ProductList/Favourite';
import { StyledPrice } from '../ui/StyledPrice';
import { styled } from 'linaria/react';
import { SliderContext } from '../ui/SharedSlider';
import { PriceDateSpan } from '../ProductPage/PriceDateSpan';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: transparent;
  }

  .product-card-detail {
    margin-top: 15px;
    padding-bottom: 50px;
    display: flex;
    .model-name {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    h3 {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.02em;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }

    h4 {
      font-weight: normal;
      font-size: 1em;
      color: ${theme.colors.darkerGrey};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  header {
    flex: 3;
    ${theme.below.lg} {
      flex: 1;
    }
  }
  .price-package-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    min-width: 30%;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  .badge-top-right {
    top: 3em;
  }
  svg {
    width: 12px !important;
    height: 12px !important;
    &.active {
      &.active {
        fill: black;
      }
    }
  }

  button {
    margin: 0 7px;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  padding: 0 10px;
  background: #f5f5f5;
  &:after {
    content: '';
    background: rgba(0, 0, 0, 0.04);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    mix-blend-mode: color;
  }

  .fav-badge {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    background: #f2f0eb;
    border-radius: 50%;
    margin-right: 6px;
    text-align: center;
    z-index: 2;
    > * {
      line-height: 25px;
      margin: 0;
      background: none;
    }
  }

  .three-for-two {
    background: #c5bbaa;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 64px;
    height: 19px;

    span {
      color: #fff;
      font-size: 10px;
      line-height: 12px;
      font-weight: 700;
    }

    ${theme.below.lg} {
      width: 56px;
      height: 17px;

      span {
        font-size: 8px;
        line-height: 10px;
      }
    }
    img {
      display: none;
    }
  }
  .discount-active {
    .three-for-two {
      top: 60px;
      ${theme.below.lg} {
        top: 50px;
      }
    }
  }
`;

export const DiscountWrapper = styled('div')`
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.brown};
  width: 51px;
  height: 51px;
  border-radius: 50%;
  z-index: 1;

  /* customer wants bigger size than design (2023-02-12) */
  p {
    color: white;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
  /* ${theme.below.lg} {
    width: 32px;
    height: 32px;
    p {
      font-size: 8px;
      line-height: 10px;
    }
  } */
`;

export function ProductCard({
  product,
  className,
  imageAspect = '260:290',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  loadImageEagerly = false,
  showModel,
  ...linkProps
}) {
  const firstImage = product?.images?.[0]?.url;
  const secondImage =
    product?.images?.[product?.images?.length - 1]?.url || firstImage;
  const [activeImage, setActiveImage] = useState(product?.images?.[0]?.url);

  const hasImages = product?.images && product?.images?.length > 0;
  const Tag = as;
  let badges = [...(product?.badges || [])];
  product?.isPackage &&
    badges?.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  // TODO: Clean this up
  let discount =
    (product?.price?.incVat / product?.previousPrice?.incVat) * 100 - 100;
  discount = discount.toFixed(0);

  if (discount === '-9' || discount === '-11') {
    discount = '-10';
  }
  if (discount === '-14' || discount === '-16') {
    discount = '-15';
  }
  if (discount === '-19' || discount === '-21') {
    discount = '-20';
  }
  if (discount === '-24' || discount === '-26') {
    discount = '-25';
  }
  if (discount === '-29' || discount === '-31') {
    discount = '-30';
  }
  if (discount === '-39' || discount === '-41') {
    discount = '-40';
  }
  if (discount === '-49' || discount === '-51') {
    discount = '-50';
  }
  // TODO: Clean this up


  const sliderContext = useContext(SliderContext);

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 0.5em 0'
        }}
        onClick={e => {
          // prevent click when dragging in slider
          if (sliderContext?.dragging) {
            e.preventDefault();
          }
        }}
        onMouseEnter={() => {
          setActiveImage(secondImage);
        }}
        onMouseLeave={() => {
          setActiveImage(firstImage);
        }}
      >
        {hasImages ? (
          <ImageWrapper>
            <Image
              sizes={imageSizes}
              cover={false}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={activeImage ?? transparentDataImg}
              modifiedDate={product.images[0].modifiedDate}
              critical={loadImageEagerly}
            ></Image>
            <Favourite className="fav-badge" product={product} />
            {badges && discount !== 0 ? (
              <div className="badge discount-active">
                <Badges badges={badges} />
              </div>
            ) : (
              <div className="badge">
                <Badges badges={badges} />
              </div>
            )}
            {parseInt(discount) !== 0 && (
              <DiscountWrapper className="discount">
                <p>{parseInt(discount)}%</p>
              </DiscountWrapper>
            )}
            <PriceDateSpan {...product?.priceDateSpan} />
          </ImageWrapper>
        ) : (
          <ImageWrapper>
            <Image src={transparentDataImg} aspect={imageAspect} />
            <PriceDateSpan {...product?.priceDateSpan} />
          </ImageWrapper>
        )}
        <section className="product-card-detail">
          <header>
            {showModel && (
              <span className="model-name">
                {
                  product?.customFields?.find(field => field.key === 'Modell')
                    ?.stringValue
                }
              </span>
            )}
            <h3>{`${product?.name} ${product?.subName}`}</h3>
          </header>
          <div className="price-package-wrapper">
            <StyledPrice
              price={product?.price}
              previousPrice={product?.previousPrice}
            />
          </div>
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
