import React, { useState } from 'react';
import useFindify from '@findify/react-bundle';
import { useHistory } from 'react-router';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';

const placeholderStyle = `      
    font-size: 11px;
    color: ${theme.colors.darkerGrey};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    opacity: 1;

    ${theme.below.lg} {
        font-size: 16px;
    }
`;

const SearchBarContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  margin-right: 20px;

  ${theme.below.lg} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-right: 0;
    padding: 20px;
    background: white;
    z-index: 2;
    width: 100%;
    div {
      width: 100%;
    }
  }

  div {
    position: relative;
    width: 100%;
  }

  input {
    border: 1px solid ${theme.colors.platinum};
    outline: none !important;
    width: 100%;
    height: 43px;
    line-height: 43px;
    padding: 0 20px;
    font-size: 11px;
    color: ${theme.colors.black};
    background: white;
    border-radius: 30px;
    
    &:focus {
      outline: none;
    }
    ${theme.below.lg} {
      width: 100%;
      font-size: 16px;
    }

    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    ::-webkit-input-placeholder {
      ${placeholderStyle}
    }

    :-ms-input-placeholder {
      ${placeholderStyle}
    }

    ::placeholder {
      ${placeholderStyle}
    }

    :focus {
      + .search-icon {
        display: none;

        + .close-icon {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    color: ${theme.colors.black};

    &.search-icon svg {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.close-icon {
      z-index: 2;
      opacity: 0;

      svg {
        stroke: ${theme.colors.black};
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
`;

const SearchBar = () => {
  const history = useHistory();
  const [container] = useFindify({ type: 'autocomplete', history });
  const [inputValue, setInputValue] = useState('');

  const resetSearchInput = () => {
    setInputValue('');
    if (typeof document !== 'undefined') {
      document.body.tabIndex = 0;
      document.body.focus();
    }
  };

  return (
    <SearchBarContainer className="findify-search-bar">
      <div>
        <input
          id="findify-search-input"
          ref={container}
          type="search"
          placeholder="Søk etter produkter"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          onKeyDown={e => {
            if (e.key.match(/escape/i)) {
              resetSearchInput();
            }
          }}
        />
        <span className="search-icon">
          <Search />
        </span>
        <span className="close-icon" onClick={resetSearchInput}>
          <Cross />
        </span>
      </div>
    </SearchBarContainer>
  );
};

export default SearchBar;
