import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const PriceDateSpanWrapper = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.isabelline};

  &.relative  {
    position: relative;
    width: auto;
    display: inline-block;
    margin-bottom: 10px;
  }

  span {
    font-size: ${theme.fontSizes.tinyParagraph};
    line-height: ${theme.fontSizes.tinyParagraph};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
`;

export const PriceDateSpan = ({ startDate, endDate, relative }) => {
  const dayjs = require('dayjs');
  dayjs.locale('nb');

  if (!startDate || !endDate) {
    return null;
  }

  const start = dayjs(startDate);
  const current = dayjs();
  const end = dayjs(endDate);

  const inRange = current >= start && current <= end;
  if (!inRange) {
    return null;
  }

  const daysLeft = end?.diff(current, 'days');

  let hoursLeft;

  if (daysLeft < 1) {
    hoursLeft = end?.diff(current, 'hours');
  }

  if (daysLeft > 6) {
    return null;
  }

  return (
    <PriceDateSpanWrapper className={relative && 'relative'}>
      {hoursLeft ? (
        <span>{`${hoursLeft} Timer igen`}</span>
      ) : (
        <span>
          {daysLeft < 1 ? `${daysLeft} Dag igjen` : `${daysLeft} Dager igjen`}
        </span>
      )}
    </PriceDateSpanWrapper>
  );
};
