import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import { ReactComponent as MyPagesIcon } from '../../../svg/MyPages.svg';
import { ReactComponent as MenuIcon } from '../../../svg/MenuIcon.svg';
import { ReactComponent as MenuClose } from '../../../svg/MenuClose.svg';
import { ReactComponent as SearchIcon } from '../../../svg/Search.svg';
import FavouriteButton from '../../ProductList/FavouriteCount';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import SearchBar from '../../Findify/Search/SearchBar';

const IconItems = styled('div')`
  display: flex;
  align-items: center;
`;
const IconItem = styled('div')`
  position: relative;
  a,
  button {
    display: block;
    padding: 10px;
    background: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    ${theme.below.lg} {
      padding: 20px 8px;
    }
  }

  svg {
    height: 21px;
    path {
      transition: all, 0.1s ease;
    }
  }

  &:hover {
    svg path {
      fill: ${theme.colors.pink};
    }
  }
`;
const IconLink = styled(Link)``;

export const SearchFieldWrapper = styled('div')`
  margin-right: 10px;
  position: relative;

  ${theme.below.lg} {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-right: 0;
    padding: 20px;
    background: white;
    z-index: 2;
    div {
      width: 100%;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 1;
    pointer-events: none;
    ${theme.below.lg} {
      left: 40px;
    }
  }
  input {
    background: white;
    border: 1px solid #e8e5de;
    border-radius: 30px;
    height: 43px;
    line-height: 43px;
    padding: 0 10px 0 50px;
    font-size: 11px;
    width: 175px;
    &:focus {
      outline: none;
    }
    ${theme.below.lg} {
      width: 100%;
    }
  }
  button,
  [data-flight-searchfield-flyout] {
    display: none;
  }
`;

export default function IconRow({
  searchOpen,
  setSearchOpen,
  favouritesFlyoutClose,
  setFavouritesFlyoutClose,
  cartFlyoutClose,
  setCartFlyoutClose
}) {
  const { routes } = useShopConfig();
  const topPosition = useHeaderHeight();
  const { hideTarget } = useContext(ModalContext);
  return (
    <IconItems>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <SearchBar />
          ) : (
            <IconItem>
              <button
                data-testid="search-button"
                id="search-button"
                onClick={() => {
                  // Closes flyout and search if open
                  hideTarget();
                  setSearchOpen(!searchOpen);
                }}
              >
                <SearchIcon />
              </button>
            </IconItem>
          )
        }
      </Above>

      <IconItem>
        <IconLink to={routes.myPages.path}>
          <MyPagesIcon />
        </IconLink>
      </IconItem>

      <IconItem>
        <FavouriteButton
          forceClose={favouritesFlyoutClose}
          handleForceClose={setCartFlyoutClose}
        />
      </IconItem>

      <IconItem>
        <CartButton
          forceClose={cartFlyoutClose}
          handleForceClose={setFavouritesFlyoutClose}
        />
      </IconItem>

      <Below breakpoint="lg">
        <IconItem>
          <DrawerTrigger
            preventOverflow={true}
            id="menu-drawer"
            coverStyles={{ top: topPosition }}
          >
            {drawer => (
              <button
                data-testid="menu-button"
                id="menu-button"
                onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
              >
                {drawer.isOpen ? <MenuClose /> : <MenuIcon />}
              </button>
            )}
          </DrawerTrigger>
        </IconItem>
      </Below>
    </IconItems>
  );
}
