import React from 'react';
import { styled } from 'linaria/react';
import FooterLinks from './FooterLinks';
import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import { theme } from '../../Theme';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import FooterLinksQuery from './FooterLinksQuery.gql';
import { ReactComponent as Klarna } from '../../../svg/klarna.svg';
import { ReactComponent as MC } from '../../../svg/MC.svg';
import { ReactComponent as Visa } from '../../../svg/visa.svg';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import {
  FooterCompanyInfoComponents,
  FooterComponents
} from '../../ContentComponents/ContentComponents';

const Wrapper = styled('section')`
  min-height: 375px;
  background: ${theme.colors.brown};
  color: #fff;
`;

const WrapFooterNav = styled(MaxWidth)`
  padding: 60px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${theme.colors.brown};
  ${theme.below.lg} {
    padding: 40px 0;
    flex-direction: column-reverse;
  }
`;

const BottomFooterWrapper = styled('div')`
  padding: 0 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 57px;
  background: ${theme.colors.beige};

  ${theme.below.lg} {
    padding: 10px;
    min-height: 82px;
    flex-direction: column-reverse;
    justify-content: space-around;
  }

  p,
  a {
    font-size: 12px;
    line-height: 22px;
    text-decoration: none;
    color: #000;
  }
`;

const BottomTextWrapper = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-start;

  ${theme.below.lg} {
    justify-content: center;
    flex: unset;
  }
`;

const PaymentRow = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
  margin-right: -20px;

  svg {
    margin-right: 20px;
  }

  ${theme.below.lg} {
    justify-content: center;
    flex: unset;
  }
`;

const PoweredByWrapper = styled('div')`
  margin-left: 10px;
`;

const InformationWrapper = styled('div')`
  padding: 0 40px;
  max-width: 35%;
  width: 35%;

  ul {
    margin-top: 20px;
  }

  p {
    font-size: 11px !important;
  }

  a,
  li,
  p {
    text-decoration: none;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: white;
  }

  a {
    &:hover {
      color: ${theme.colors.beige};
    }
  }

  ${theme.below.lg} {
    max-width: 80%;
    width: 80%;
    padding: 0;
  }

  p {
    ${theme.below.lg} {
      /* margin-top: 20px; */
    }
  }
`;

const LinksWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 80px;

  ${theme.below.lg} {
    padding: 0 40px;
    flex-direction: column;
    div {
      margin-bottom: 10px;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.02em;
  }

  li,
  a {
    color: white;
    text-decoration: none;
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.02em;
    margin: 3px 0;

    &:hover {
      color: ${theme.colors.beige};
    }
  }
`;

const LogoLink = styled(Link)`
  svg {
    margin-bottom: 20px;
    width: 170px;
    height: 31px;
    ${theme.below.lg} {
      margin-top: 20px;
      /* margin-bottom: 0px; */
      margin-left: 40px;
    }
  }
  svg path {
    fill: white;
  }
`;

const Information = ({ info }) => {
  return (
    <InformationWrapper>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
      {info && (
        <ContentRenderer
          items={info.data.items}
          components={FooterCompanyInfoComponents}
        />
      )}
      <SocialLinks />
    </InformationWrapper>
  );
};

const BottomFooter = () => {
  return (
    <BottomFooterWrapper>
      <BottomTextWrapper>
        <p>© 2023 Home & Cottage</p>
        <PoweredByWrapper>
        </PoweredByWrapper>
      </BottomTextWrapper>

      <PaymentRow>
        <Klarna />
        <MC />
        <Visa />
      </PaymentRow>
    </BottomFooterWrapper>
  );
};

const Footer = () => {
  // TODO - Needs to split into fragments
  const { data } = useQuery(FooterLinksQuery, {
    variables: {
      customerserviceid: 52,
      companyid: 53,
      assortmentid: 381,
      infoid: 396
    }
  });

  const customerService = data?.footerCustomerService;
  const company = data?.footerCompany;
  const assortment = data?.footerAssortment;
  const companyInfo = data?.footerCompanyInfo;

  return (
    <>
      <Wrapper>
        <WrapFooterNav>
          {companyInfo && <Information info={companyInfo} />}
          <LinksWrapper>
            {customerService && <FooterLinks links={customerService} />}
            {company && <FooterLinks links={company} />}
            {assortment && (
              <ContentRenderer
                items={assortment?.data?.items}
                components={FooterComponents}
              />
            )}
          </LinksWrapper>
        </WrapFooterNav>
      </Wrapper>
      <BottomFooter />
    </>
  );
};

export default React.memo(Footer);
