import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { validateNumber } from '../../utils/HelpFunctions';

const SpacerWrapper = styled('div')`
  height: ${props => props.height + 'px'};
  width: 100%;
  max-width: 72.75rem;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const DividerWrapper = styled('div')`
  width: ${props => props.width + '%'};
`;

export const Spacer = ({
  mobileHeight,
  desktopHeight,
  divider,
  dividerWidth
}) => {
  const spaceDesktopHeight = validateNumber(desktopHeight?.value) ?? 20;
  const spaceMobileHeight = validateNumber(mobileHeight?.value) ?? 10;
  const spaceWidth = validateNumber(dividerWidth?.value) ?? 100;

  return (
    <Above breakpoint="md">
      {matches => (
        <SpacerWrapper
          height={matches ? spaceDesktopHeight : spaceMobileHeight}
        >
          {divider?.value && (
            <DividerWrapper
              width={spaceWidth}
              dangerouslySetInnerHTML={{
                __html: divider?.value
              }}
            />
          )}
        </SpacerWrapper>
      )}
    </Above>
  );
};
