import CategoryLink from '@jetshop/ui/CategoryLink'
import { styled } from 'linaria/react'
import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import {
  filterOutMenuContentFromSubCategories,
  findMenuContentCategoryId
} from '../../../ContentComponents/MenuContent'
import { theme } from '../../../Theme'
import UIMaxWidth from '../../MaxWidth'
import { MenuContentCategory } from './MenuContentCategory'

// Limit the menu to our global MaxWidth
const MaxWidth = styled(UIMaxWidth)`
  width: 100%;
  display: block;
  columns: 4;
  padding: 20px 0;
  column-gap: 20px;
  a {
    text-decoration: none;
    color: ${theme.colors.black};
    display: block;
    width: 100%;
    padding: 5px 10px;
    text-align: left;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.2;
    letter-spacing: 0.5px;
    :hover {
      text-decoration: underline;
    }

    &.show-all-link {
      font-weight: bold;
    }
  }
`

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  margin: 0 auto;
  min-width: 50vw;
  width: 100%;
`

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
`

const Wrapper = styled('section')`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  overflow: hidden;
  z-index: 10;
  will-change: height;
  transition: height 0.3s ease-in-out;
  max-height: 80vh;
  overflow-y: scroll;

  h2 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1em;
  }
`

const NoThirdTierItems = styled('div')`
  display: block;
  width: 100%;
  h2 {
    font-size: inherit;
  }
`

const Column = styled('div')`
  width: 100%;
  max-width: 250px;
  flex: none;

  &.category-column {
    border-left: 1px solid #f2f0eb;
    padding-left: 0px;
    padding-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
`

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  const [menuContentId, setMenuContentId] = useState()

  useEffect(() => {
    setMenuContentId(findMenuContentCategoryId(activeCategory))
  }, [activeCategory])

  const editedActiveCategory =
    filterOutMenuContentFromSubCategories(activeCategory)
  return (
    <Wrapper>
      <InnerWrapper>
        <ContentFit>
          <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
            <MaxWidth>
              {editedActiveCategory &&
                editedActiveCategory.subcategories.map((cat, index) => (
                  <Column key={index} className="category-column">
                    {cat && (
                      <CategoryLink
                        category={cat}
                        onClick={closeNav}
                        className="show-all-link"
                      >
                        {cat.name}
                      </CategoryLink>
                    )}
                    {cat &&
                      cat.subcategories.map((cat, index) => {
                        return (
                          <React.Fragment key={cat.id}>
                            <NoThirdTierItems>
                              <h2>
                                <CategoryLink category={cat} onClick={closeNav}>
                                  {cat.name}
                                </CategoryLink>
                              </h2>
                            </NoThirdTierItems>
                          </React.Fragment>
                        )
                      })}
                  </Column>
                ))}

              {menuContentId && (
                <MenuContentCategory
                  menuContentId={menuContentId}
                  closeNav={closeNav}
                />
              )}
            </MaxWidth>
          </AnimateHeight>
        </ContentFit>
      </InnerWrapper>
    </Wrapper>
  )
}

export default SubMenuWrapper
