import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FacebookLogo } from '@jetshop/ui/svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/insta.svg';
import { ReactComponent as YoutubeLogo } from '../../../svg/youtube.svg';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
  margin-right: 0;
  margin-left: -0.5rem;
  margin-top: 50px;
  ${theme.below.lg} {
    margin: 0 0 0 32px;
  }
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
  }

  li {
    margin: 0 0.5rem;
  }
`;

const LogoWrapper = styled('span')`
  width: 1.375rem;
  height: 1.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${theme.below.md} {
    margin-right: 0.25rem;
  }
  svg {
    width: 20px;
    max-height: 80%;
  }
`;

const SocialLinks = () => (
  <Wrapper>
    <ul>
      <li>
        <a className="social-links" aria-label="Facebook" href="/">
          <LogoWrapper>
            <FacebookLogo />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a className="social-links" aria-label="Instagram" href="/">
          <LogoWrapper>
            <InstagramLogo />
          </LogoWrapper>
        </a>
      </li>
      <li>
        <a className="social-links" aria-label="Youtube" href="/">
          <LogoWrapper>
            <YoutubeLogo />
          </LogoWrapper>
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
