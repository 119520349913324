import React from 'react';
import { Link } from 'react-router-dom';

export const CustomLinks = ({ children, title }) => {
  return (
    <div>
      <h3>{title?.value}</h3>
      <ul>{children}</ul>
    </div>
  );
};

export const CustomLinksItem = ({ text, link }) => {
  return (
    <li>
      <Link to={link?.value}>{text?.value}</Link>
    </li>
  );
};
