import { useNotification } from '@jetshop/core/components/Notifications'
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList'
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart'
import t from '@jetshop/intl'
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer'
import { css } from 'linaria'
import { styled } from 'linaria/react'
import React from 'react'
import { CartUsp } from '../Cart/CartFlyout'
import addMultipleToCartMutation from '../Cart/queries/addMultipleToCart.gql'
import cartQuery from '../Cart/queries/cartQuery.gql'
import useHeaderHeight from '../hooks/useHeaderHeight'
import { theme } from '../Theme'
import { baseStyles } from '../ui/Button'
import { DynamicUsps } from '../ui/DynamicUsps'
import { Product } from './Product'

const DrawerWrapper = styled('div')`
  > div {
    background: ${theme.colors.beige};
    top: ${props => props.topPosition}px;
  }
`

const Flyout = styled('div')`
  background: ${theme.colors.beige};
  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }
`

const CartItems = styled('section')`
  background: white;
  padding: 20px;
`

const Summary = styled('section')`
  padding: 20px;
`
const AddToCartButton = styled('button')`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 5px 0;
`
const RemoveButton = styled('button')`
  ${baseStyles};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background: ${theme.colors.darkbeige};
  color: white;
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  border: 0;
`

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`

const FavouritesFlyoutView = ({ modal, ...rest }) => {
  const listId = null

  const { products, loading } = useProductListItems(listId)
  const [trigger] = useNotification()

  const validItems = getItemsForAddToCart(products)
  const validItemCount = validItems?.length

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        )
      }
    }
  )

  const noProductsInList = products?.length === 0 && !loading
  const loadingInitialRender = products?.length === 0 && loading

  if (noProductsInList) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em', background: 'white' }}>
          <h2 style={{ marginBottom: '1em' }}>{t('Saved items')}</h2>
          <p>{t('There are no products in your saved items list.')}</p>
        </div>
        <Summary>
          <RemoveButton onClick={modal.hideTarget}>
            {t('Continue Shopping')}
          </RemoveButton>
          <CartUsp>
            <DynamicUsps id={392} />
          </CartUsp>
        </Summary>
      </Flyout>
    )
  }

  if (loadingInitialRender) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em', background: 'white' }}>
          <h2 style={{ marginBottom: '1em' }}>{t('Saved items')}</h2>
          <p>{t('Loading your saved items…')}</p>
        </div>
        <Summary>
          <RemoveButton onClick={modal.hideTarget}>
            {t('Continue Shopping')}
          </RemoveButton>
        </Summary>
      </Flyout>
    )
  }

  return (
    <Flyout {...rest}>
      <CartItems>
        <ul className="product-grid">
          {products.map((product, index) => {
            return (
              <li
                key={product.variant?.articleNumber || product.articleNumber}
                data-valid={product.validation.status}
              >
                <Product listId={listId} product={product} />
              </li>
            )
          })}
        </ul>
      </CartItems>

      <Summary>
        <AddToCartButton
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {validItemCount > 0
            ? mutationLoading
              ? t('One moment…')
              : t(
                  '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                  { productCount: validItemCount }
                )
            : t('No valid items to add to cart')}
        </AddToCartButton>
        <ClearList listId={listId}>{t('Clear list')}</ClearList>
        <CartUsp>
          <DynamicUsps id={392} />
        </CartUsp>
      </Summary>
    </Flyout>
  )
}

function getItemsForAddToCart(products) {
  return products
    .filter(product => {
      return (
        product.validation.status === 'valid' &&
        product.stockStatus?.text !== 'Ikke på lager'
      )
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }))
}
function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>
}
function ClearList({ children, listId }) {
  const { clear } = useProductList(listId)
  return (
    <RemoveButton secondary onClick={clear}>
      {children}
    </RemoveButton>
  )
}

const CartFlyout = props => {
  const topPosition = useHeaderHeight()
  return (
    <DrawerTarget id="favourites-drawer">
      {drawer => (
        <DrawerWrapper topPosition={topPosition}>
          <Drawer isOpen={drawer.isOpen} right>
            <FavouritesFlyoutView modal={drawer} {...props} />
          </Drawer>
        </DrawerWrapper>
      )}
    </DrawerTarget>
  )
}

export default CartFlyout
