import { styled } from 'linaria/react';
import React from 'react';
import { uspsComponents } from '../ContentComponents/ContentComponents';
import DynamicCategoryContentRenderer from '../ContentComponents/DynamicContentRenderer';

const DynamicUspsWrapper = styled('div')`
  width: 100%;
  .text-row-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .journal {
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0;

    p {
      font-size: 11px !important;
      line-height: 200%;
    }

    &:before {
      margin-right: 4px;
      display: block;
      content: ' ';
      background-image: url('../../svg/Check.svg');
      background-size: 11px 11px;
      height: 11px;
      width: 11px;
    }
  }

  .journal + .journal {
    margin-left: 12px;
  }
`;

export const DynamicUsps = ({ id }) => {
  return (
    <DynamicUspsWrapper>
      <DynamicCategoryContentRenderer
        categoryId={id}
        rendererComponents={uspsComponents}
      />
    </DynamicUspsWrapper>
  );
};
