import { useState, useEffect } from 'react';
import 'dayjs/locale/nb';

export const dateParse = text => {
  const dayjs = require('dayjs');
  dayjs.locale('nb');
  var weekOfYear = require('dayjs/plugin/weekOfYear');
  dayjs.extend(weekOfYear);

  // CURRENT DATE
  const currentDate = dayjs();
  // DELIVERY TIME EG. 81 days

  let regexDays = text?.replace(/\D/g, '');

  // FIX FOR OTHER STATUS TEST RESOLVING IN DATE FORMAT (YYYYMMDD)
  if (regexDays?.length === 8) {
    const newDate = dayjs(regexDays);
    regexDays = newDate?.diff(currentDate, 'days');
  }

  const deliveryTime = parseInt(regexDays);

  if (!text?.length > 0) {
    return null;
  }

  const actualDelivery = currentDate.add(deliveryTime, 'day').add(1, 'day');
  const productCommentDate = dayjs(actualDelivery).format('YYYY-MM-DD')+'T00:00:00+00:00';
  const weekNumber = actualDelivery.week();
  const month = actualDelivery.format('MMMM');
  const year = actualDelivery.year();

  return { weekNumber, month, year, productCommentDate };
};

export const useModifiedStockStatus = (text, status) => {
  const [modifiedStockText, setModifiedStockText] = useState(text);
  const [modifiedStockStatus, setModifiedStockStatus] = useState(status);

  useEffect(() => {
    if (status === 'outOfStock') {
      setModifiedStockText('Utsolgt på nettlager');
    }

    const delivery = dateParse(text);

    if (text?.startsWith('På')) {
      setModifiedStockText(`På nettlager`);
    }

    if (text?.startsWith('Kommer')) {
      setModifiedStockStatus('notifyWhenBack');
      setModifiedStockText(
        `Forventet på lager uke ${delivery?.weekNumber}, ${delivery?.month} ${delivery?.year}.`
      );
    }

    if (text?.startsWith('Ca')) {
      setModifiedStockStatus('notifyWhenBack');
      setModifiedStockText(
        `Bestillingsvare - Forventet levering uke ${delivery?.weekNumber}, ${delivery?.month} ${delivery?.year}.`
      );
    }

    if (text?.startsWith('Ikke')) {
      setModifiedStockStatus('outOfStock');
    }

    return () => {
      setModifiedStockText(null);
      setModifiedStockStatus(null);
    };
  }, [text, status]);

  return { modifiedStockText, modifiedStockStatus };
};
