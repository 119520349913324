import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableWindowedCategoryPage } from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';
import ProductPage from './components/ProductPage/ProductPage.loadable';
import ContentPage from './components/ContentPage/ContentPage.loadable';
import { persistedQueries } from './persistedQueries';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'homeandcottage',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io/',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: persistedQueries
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: false,
  sentry: {
    clientDSN:
      'https://f523acc6b68145a5bc7eac8cf00fd579@o4504999543767040.ingest.sentry.io/4504999546585088',
    serverDSN:
      'https://f523acc6b68145a5bc7eac8cf00fd579@o4504999543767040.ingest.sentry.io/4504999546585088',
    ignoreErrors: []
  },
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          NKR: {
            style: 'decimal',
            currency: 'NKR',
            minimumFractionDigits: 0,
            currencyDisplay: 'name'
          }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode:
    typeof process.env.REACT_APP_SINGLE_DOMAIN_MODE === 'undefined'
      ? false
      : process.env.REACT_APP_SINGLE_DOMAIN_MODE, // default to false if this env var is not set
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  serverTrackers: [
    createFacebookTracker({
      pixelId: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey: 'AIzaSyAD5KO3xP8LB-C-2bFT8CDY1zfNAwPjocQ',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [ProductPage],
      Category: [LoadableWindowedCategoryPage],
      Page: [ContentPage]
    }
  },
  routes: {
    myPages: {
      path: '/minesider',
    },
    login: {
      path: '/login',
    },
    signup: {
      path: '/signup',
    },
    logout: {
      path: '/logout',
    },
    forgotPassword: {
      path: '/glemt-passord',
    },
    resetPassword: {
      path: '/reset-password',
    }
  },
};
export default config;
