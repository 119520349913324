import { css } from 'linaria';

import ProximaWoff from './ProximaNova-Regular.woff';
import ProximaWoff2 from './ProximaNova-Regular.woff2';

import ProximaBoldWoff from './ProximaNova-Bold.woff';
import ProximaBoldWoff2 from './ProximaNova-Bold.woff2';

import Northwell from './Northwell.woff';
import GaramondNarrowOtf from './GaramondNarrow.otf';
import GaramondNormalOtf from './GaramondNormal.otf';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return css`
    :global(html) {
      /* Narrow font */
      /* @font-face {
        font-family: 'Garamond';
        src: local('Garamond'), local('Garamond'),
          url(${GaramondNarrowOtf}) format('otf');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      } */

      /* Regular font */

      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova'), local('ProximaNova'),
          url(${ProximaWoff2}) format('woff2'), url(${ProximaWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }


      @font-face {
        font-family: 'Garamond';
        src: local('Garamond'), local('Garamond'),
          url(${GaramondNarrowOtf}) format('otf');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      /* Bold font */
      @font-face {
        font-family: 'Proxima Nova';
        src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
          url(${ProximaBoldWoff2}) format('woff2'),
          url(${ProximaBoldWoff}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Garamond';
        src: local('Garamond'), local('Garamond'),
          url(${GaramondNormalOtf}) format('otf');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'Northwell';
        src: local('Northwell'), local('Northwell'),
          url(${Northwell}) format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      &.proxima-nova-ready body {
        font-family: 'Proxima Nova', sans-serif;
        line-height: 1;
        letter-spacing: 0;
        word-spacing: 0;
        font-weight: normal;
      }
    }
  `;
}
