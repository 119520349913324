import React from 'react';
import { allContentComponents } from '../ContentComponents/ContentComponents';
import DynamicCategoryContentRenderer from '../ContentComponents/DynamicContentRenderer';

export const ThankYou = () => {
  return (
    <DynamicCategoryContentRenderer
      categoryId={1290}
      rendererComponents={allContentComponents}
    />
  );
};
