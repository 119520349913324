import { styled } from 'linaria/react'
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger'
import useHeaderHeight from '../hooks/useHeaderHeight'
import { useProductList } from '@jetshop/core/hooks/ProductList'
import React, { useEffect } from 'react'
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg'
import Badge from '../ui/Badge'

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  position: relative;
`

function FavouritesButton({
  target,
  count,
  className,
  forceClose,
  handleForceClose
}) {
  useEffect(() => {
    // Closing Favourite flyout if open when Force close is triggered from outside
    if (target.isOpen) {
      target.hideTarget()
    }

    // Reset Force close handler
    handleForceClose(false)
  }, [forceClose]) // eslint-disable-line

  function handleClick() {
    if (target.isOpen) {
      target.hideTarget()
    } else {
      // Closing cart flyout if open
      handleForceClose(true)

      // Timeout is needed to avoid react render transition at the same time.
      setTimeout(() => {
        target.showTarget()
      }, 1)
    }
  }

  return (
    <Button
      className={className}
      data-testid="favourites-button"
      onClick={handleClick}
    >
      <div className="badge-svg-wrapper" style={{ position: 'relative' }}>
        {count > 0 && <Badge text={count} />}
        <HeartSVG />
      </div>
    </Button>
  )
}

function FavouritesButtonFlyout({
  className,
  listId,
  forceClose,
  handleForceClose
}) {
  const { count } = useProductList(listId)
  const topPosition = useHeaderHeight()
  return (
    <DrawerTrigger
      preventOverflow={true}
      id="favourites-drawer"
      coverStyles={{ top: topPosition }}
    >
      {drawer => (
        <FavouritesButton
          data-testid="favourites-button"
          id="favourites-button"
          className={className}
          target={drawer}
          count={count}
          forceClose={forceClose}
          handleForceClose={handleForceClose}
        />
      )}
    </DrawerTrigger>
  )
}

export default FavouritesButtonFlyout
