import React from 'react';
import Products from '../ProductPage/Products.gql';
import { useQuery } from '@apollo/react-hooks';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { useIntl } from '@jetshop/intl';
import { TitleAndLink } from './TitleAndLink';
import { theme } from '../Theme';
import { StyledPrice } from '../ui/StyledPrice';
import { AddToCartQtyButton } from '../ui/AddToCartQtyButton';
import { Link } from 'react-router-dom';

const AddToCartListContainer = styled('div')`
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;

  .title-and-link {
    padding: 0;
    margin-bottom: 0px;
  }
  .product-image {
    background: #f9f9f9;
  }
`;

export const AddToCartList = ({ title, products, noProductsCallback }) => {
  const { data, loading } = useQuery(Products, {
    variables: {
      articles: products
    },
    errorPolicy: 'all'
  });

  const presentableProducts = data?.products;

  const t = useIntl();

  if (presentableProducts?.length === 0 && !loading) {
    noProductsCallback();
    return (
      <p style={{ marginTop: '3rem', textAlign: 'center' }}>
        {t('Not available')}
      </p>
    );
  }
  return (
    <AddToCartListContainer>
      <TitleAndLink title={{ value: title }} />
      {presentableProducts?.map((product, index) => {
        return (
          <AddToCartListItem
            key={index + product?.articleNumber}
            product={product}
          />
        );
      })}
    </AddToCartListContainer>
  );
};

const AddToCartListItemContainer = styled('div')`
  height: fit-content;
  width: 100%;
  position: relative;
  display: flex;
  max-height: 82px;
  min-height: 82px;
  column-gap: 10px;
  width: 100%;
  max-width: 375px;

  [data-flight-image-container] {
    flex: 1;
  }
  .product-image {
    width: 82px;

    @media only screen and (max-width: 380px) {
      width: 60px;
    }
  }
  .item-container {
    display: flex;
    flex: 2;
    height: 82px;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;

    .product-name-row {
      font-size: ${theme.fontSizes.tinyParagraph};
      line-height: ${theme.lineHeight.tinyParagraph};
      margin-bottom: 10px;
    }

    .product-action-row {
      display: flex;
    }

    .product-price-row {
      [data-flight-price] {
        display: flex;
        flex-direction: row;
        > * {
          margin-right: 5px;
        }
        .new-price,
        .price {
          font-size: ${theme.fontSizes.tinyPrice};
          line-height: ${theme.lineHeight.tinyPrice};
          font-weight: ${theme.fontWeights.semibold};
        }

        .old-price {
          font-size: 10px;
          line-height: 16px;
          color: ${theme.colors.mediumgrey};
        }
      }
    }
  }
`;

const AddToCartListItem = ({ product }) => {
  return (
    <AddToCartListItemContainer>
      <Link to={product?.primaryRoute?.path}>
        <div className="product-image">
          {
            <Image
              src={product?.images?.[0]?.url}
              aspect="260:290"
              
              cover={false}
            />
          }
        </div>
      </Link>
        <div className={'item-container'}>
          <div className="product-info">
            <div className="product-name-row">{product?.name}</div>
            <div className="product-price-row">
              <StyledPrice
                price={product?.price}
                previousPrice={product?.previousPrice}
                hideSavings={true}
              />
            </div>
          </div>
          <div className="product-action-row">
            <AddToCartQtyButton product={product} />
          </div>
        </div>
    </AddToCartListItemContainer>
  );
};
