import { Price } from '@jetshop/ui/Price';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const StyledPriceWrapper = styled('div')`
  [data-flight-price] {
    display: flex;
    flex-direction: column;

    .price {
      font-size: 16px;
      line-height: 17px;
      font-weight: 700;
      text-align: left;
    }
    .new-price {
      margin: 0;
      color: #000;
      font-size: 16px;
    }
    .old-price {
      font-size: 12px;
      color: #868686;
      font-weight: 400;
      &::before {
        content: 'før ';
      }
    }
  }
`;

const SavingsWrapper = styled('div')`
  font-size: 16px;
  line-height: 16px;
  color: #4e3525;
  &::after {
    content: ',-';
  }
  ${theme.below.lg} {
    padding-bottom: 1px;
    border-bottom: 1px solid #4e3525;
  }
`;

export const StyledPrice = ({ price, previousPrice, hideSavings }) => {
  const savings = (previousPrice?.incVat - price?.incVat).toFixed(0);

  return (
    <StyledPriceWrapper className="styled-price">
      <Price price={price} previousPrice={previousPrice}></Price>
      {savings > 0 && !hideSavings && (
        <SavingsWrapper className="savings-wrapper">
          Spar {savings}
        </SavingsWrapper>
      )}
    </StyledPriceWrapper>
  );
};
