import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import {
  filterChildrenByKey,
  numberToSpelledText,
  validateNumber
} from '../../utils/HelpFunctions';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import Image from '@jetshop/ui/Image';
import { BoxContent, BoxContentType } from './BoxContent';
import { Above } from '@jetshop/ui/Breakpoints';

const BoxGridWrapper = styled(MaxWidth)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: ${props => props.gap}px;
  grid-row-gap: ${props => props.gap}px;

  ${theme.below.lg} {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }

  &.fullwidth {
    padding: 0;
    max-width: 100%;
    ${theme.above.sm} {
      padding: 0;
    }
  }

  &.items-4 {
    .div-one {
      grid-area: 1 / 1 / 3 / 3;
    }
    .div-two {
      grid-area: 1 / 3 / 2 / 5;
    }
    .div-three {
      grid-area: 2 / 3 / 3 / 4;
    }
    .div-four {
      grid-area: 2 / 4 / 3 / 5;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 2;
      }
      .div-four {
        grid-area: 4 / 2 / 5 / 3;
      }
    }
  }

  &.items-3 {
    .div-one {
      grid-area: 1 / 1 / 3 / 3;
    }
    .div-two {
      grid-area: 1 / 3 / 2 / 5;
    }
    .div-three {
      grid-area: 2 / 3 / 3 / 5;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 3;
      }
    }
  }

  &.items-2 {
    .div-one {
      grid-area: 1 / 1 / 3 / 3;
    }
    .div-two {
      grid-area: 1 / 3 / 3 / 5;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 5 / 3;
      }
    }
  }

  &.items-1 {
    .div-one {
      grid-area: 1 / 1 / 3 / 5;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
    }
  }
`;

export const BoxGrid = ({ fullWidth, desktopGap, mobileGap, children }) => {
  const desktopGapSize = validateNumber(desktopGap?.value) ?? 20;
  const mobildeGapSize = validateNumber(mobileGap?.value) ?? 10;

  const titleAndTextChildren = filterChildrenByKey(
    children,
    'titleAndLink',
    true
  );
  const boxChildren = filterChildrenByKey(children, 'boxGridItem', true);

  //Injects more props to children
  const indexedChildren = boxChildren?.map((child, index) => {
    const indexClassName = { name: 'indexClassName', value: index + 1 };
    const fullW = { name: 'fullWidth', value: fullWidth?.value };
    return {
      ...child,
      props: { ...child?.props, indexClassName, fullW }
    };
  });
  const childClassName = indexedChildren?.length;

  return (
    <Above breakpoint="lg">
      {matches => (
        <>
          {titleAndTextChildren}
          <BoxGridWrapper
            gap={matches ? desktopGapSize : mobildeGapSize}
            className={cx(
              `items-${childClassName}`,
              fullWidth?.value && 'fullwidth'
            )}
          >
            {indexedChildren}
          </BoxGridWrapper>
        </>
      )}
    </Above>
  );
};

const BoxGridItemWrapper = styled('div')`
  position: relative;
  &.overlay {
    [data-flight-image-container] {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
        pointer-events: none;
      }
    }
  }

  &.div-one {
    [data-flight-image-container] {
      height: 100%;
      max-height: calc(72.75rem / 1.95);
      min-height: calc(72.75rem / 1.95);
    }

    &.fullwidth {
      [data-flight-image-container] {
        max-height: calc(100vw / 1.85);
        min-height: calc(100vw / 1.85);
      }
    }
    ${theme.below.lg} {
      [data-flight-image-container] {
        height: calc(100vw * 1.1) !important;
        max-height: calc(100vw * 1.1) !important;
        min-height: calc(100vw * 1.1) !important;
      }
    }
  }
`;

export const BoxGridItem = ({
  fullW,
  desktopImage,
  mobileImage,
  link,
  textColor,
  topText,
  topTextFamily,
  bottomText,
  bottomTextFamily,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition,
  indexClassName,
  overlay
}) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  return (
    <BoxGridItemWrapper
      className={cx(
        'div-' + numberToSpelledText(indexClassName?.value), // fix for Safari
        fullW?.value && 'fullwidth',
        overlay?.value && 'overlay'
      )}
    >
      <LinkEvaluator link={link}>
        <Above breakpoint="lg">
          {matches => (
            <Image
              sizes={imageSizes}
              src={matches ? desktopImage?.value : mobileImage.value}
              fillAvailableSpace
            />
          )}
        </Above>

        <BoxContent
          verticalPosition={verticalPosition}
          horizontalPosition={horizontalPosition}
          textColor={textColor}
          topText={topText}
          topTextFamily={topTextFamily}
          bottomText={bottomText}
          bottomTextFamily={bottomTextFamily}
          buttonTheme={buttonTheme}
          buttonText={buttonText}
          forType={BoxContentType.GRID}
        />
      </LinkEvaluator>
    </BoxGridItemWrapper>
  );
};
