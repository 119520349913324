import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';

const ProductGridWrapper = styled(MaxWidth)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding: 0; */

  .title-and-link {
    padding: 0;
  }
  li {
    list-style-type: none;
  }
  .product-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
`;

export const ProductGrid = ({ children }) => {
  return (
    <ProductGridWrapper>
      <div className="product-grid">{children}</div>
    </ProductGridWrapper>
  );
};
