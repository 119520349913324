import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Minus } from '../../svg/Minus.svg';
import { ReactComponent as Plus } from '../../svg/Plus.svg';
import { GetInStoreList } from '../ProductPage/GetInStore/GetInStoreList';
import { theme } from '../Theme';
import { LocationStateContext } from './StoreLocator';
import UserLocation from './UserLocation';

const StoreListContainer = styled('ul')`
  height: calc(100% - 94px);
  overflow-y: auto;

  .highlight {
    background: yellow;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

const StoreContainer = styled('li')`
  width: 100%;
  background: ${theme.colors.white};
  padding: 20px 0;
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.8);

  font-size: 14px;
  line-height: 1.4;

  a {
    color: rgba(0, 0, 0, 0.8);
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }

  > div {
    &.expanded-content {
      padding: 0;
      max-height: 0;
      overflow: hidden;
      transition-property: max-height;
      transition-duration: 0.2s;
    }
  }

  &.expanded {
    > div.expanded-content {
      max-height: 600px;
    }

    svg.carrot {
      transform: rotate(0deg);
    }
  }

  svg.carrot {
    transition-property: transform;
    transition-duration: 0.2s;
  }
`;

const AccordionWrapper = styled('div')`
  padding: 20px 0;
  margin-top: 40px;
`;
const AccordionTitle = styled('button')`
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0;
  appearance: none;
  width: 100%;
  font-size: 1rem;
  padding: 1rem 0;

  + div {
    border-bottom: 1px solid #f2f0eb;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
  svg {
    width: 10px;
  }
`;

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const humanDistance = distance => {
  if (!distance) {
    return null;
  }

  return distance > 10
    ? t('{km} km', { km: Math.round(distance) })
    : distance > 1
    ? t('{km} km', { km: distance.toFixed(1) })
    : t('{m} m', { m: Math.round(distance * 1000) });
};

const Store = ({
  storeNr,
  distances,
  store: { id, name, address1, openHours, description, coordinates, contact }
}) => {
  const locationState = useContext(LocationStateContext);
  const { routes } = useShopConfig();
  const expanded = id === locationState.expandedLocation;
  const storeUrl = routes?.store?.path + '/' + id;

  return (
    <StoreContainer
      onMouseEnter={() => locationState.setHighlightedLocation(id)}
      onMouseLeave={() => locationState.setHighlightedLocation(null)}
      tabIndex={0}
      className={cx(expanded ? 'expanded' : null)}
    >
      <div>
        <p>
          <strong dangerouslySetInnerHTML={{ __html: name }} />
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: address1 }}
          style={{ marginBottom: '24px' }}
        />
        <div
          dangerouslySetInnerHTML={{ __html: contact }}
          style={{ marginBottom: '24px' }}
        />
        {!expanded && distances[id] && (
          <p style={{ color: '#8a8a8a' }}>
            {t('Distance')}: {humanDistance(distances[id])}
          </p>
        )}
        <Link
          to={storeUrl}
          style={{ textDecoration: 'underline' }}
          onClick={() => locationState.toggleExpandedLocation(id)}
        >
          {t('See opening hours and more information')}
        </Link>
      </div>
    </StoreContainer>
  );
};

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <Minus /> : <Plus />}</IndicatorIcon>
);

const StoreList = ({
  canExpand,
  stores,
  setUserLocation,
  distances,
  getInStoreCallback
}) => {
  const regions = groupBy(stores, store => store.region);
  const regionsList = Array.from(regions.entries());
  return (
    <StoreListContainer className={getInStoreCallback && 'get-in-store'}>
      <UserLocation setUserLocation={setUserLocation} />

      {getInStoreCallback ? (
        <GetInStoreList
          canExpand={canExpand}
          stores={stores}
          getInStoreCallback={e => {
            getInStoreCallback(e);
          }}
          distances={distances}
        />
      ) : (
        <AccordionWrapper>
          <Accordion single>
            {({ openIndexes, handleClick, AccordionContent }) =>
              regionsList.map((region, index) => {
                const open = openIndexes.includes(index);
                return (
                  <div key={index}>
                    <AccordionTitle onClick={() => handleClick(index)}>
                      <span>{region[0]}</span>
                      <AccordionIndicator
                        isOpen={openIndexes.includes(index)}
                      />
                    </AccordionTitle>
                    <AccordionContent isOpen={open}>
                      <>
                        {region[1].map((store, index) => (
                          <Store
                            key={index}
                            storeNr={index + 1}
                            store={store}
                            distances={distances}
                          />
                        ))}
                      </>
                    </AccordionContent>
                  </div>
                );
              })
            }
          </Accordion>
        </AccordionWrapper>
      )}
    </StoreListContainer>
  );
};
export default StoreList;
