import React, { useState } from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import {
  filterChildrenByKey,
  numberToSpelledText,
  validateNumber
} from '../../utils/HelpFunctions';
import Image from '@jetshop/ui/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { injectContentPropsToChild } from '../../utils/ContentProp';
import { CartCircle } from '../ui/CartCircle';
import { PopupContent } from '../ui/PopupContent';
import { AddToCartList } from './AddToCartList';

const ProductsPopupGridWrapper = styled(MaxWidth)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: ${props => props.gap}px;
  grid-row-gap: ${props => props.gap}px;

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(11, 1fr);
    padding: 0 20px;
  }

  &.fullwidth {
    padding: 0;
    max-width: 100%;
  }

  .div-one {
    grid-area: 1 / 1 / 8 / 3;
    ${theme.below.lg} {
      grid-area: 1 / 1 / 7 / 3;
    }
  }
  .div-two {
    grid-area: 1 / 3 / 5 / 4;
    ${theme.below.lg} {
      grid-area: 7 / 1 / 10 / 2;
    }
  }
  .div-three {
    grid-area: 5 / 3 / 8 / 4;
    ${theme.below.lg} {
      grid-area: 7 / 2 / 9 / 3;
    }
  }
  .div-four {
    grid-area: 1 / 4 / 4 / 5;
    ${theme.below.lg} {
      grid-area: 10 / 1 / 12 / 2;
    }
  }
  .div-five {
    grid-area: 4 / 4 / 8 / 5;
    ${theme.below.lg} {
      grid-area: 9 / 2 / 12 / 3;
    }
  }
`;

export const ProductsPopupGrid = ({
  fullWidth,
  desktopGap,
  mobileGap,
  children
}) => {
  const desktopGapSize = validateNumber(desktopGap?.value) ?? 20;
  const mobildeGapSize = validateNumber(mobileGap?.value) ?? 10;
  const titleAndTextChildren = filterChildrenByKey(
    children,
    'titleAndLink',
    true
  );

  const productsPopupGridItemChildren = filterChildrenByKey(
    children,
    'productsPopupGridItem',
    true
  );

  const injectedProductsPopupGridItemChildren =
    productsPopupGridItemChildren.map((child, index) => {
      return injectContentPropsToChild(child, [
        {
          name: 'indexClassName',
          value: index + 1
        },
        {
          name: 'fullWidth',
          value: fullWidth?.value
        }
      ]);
    });

  return (
    <Above breakpoint="lg">
      {matches => (
        <>
          {titleAndTextChildren}
          <ProductsPopupGridWrapper
            gap={matches ? desktopGapSize : mobildeGapSize}
          >
            {injectedProductsPopupGridItemChildren}
          </ProductsPopupGridWrapper>
        </>
      )}
    </Above>
  );
};

const ProductsPopupGridItemWrapper = styled('div')`
  cursor: pointer;
  position: relative;
  &.div-one {
    [data-flight-image-container] {
      height: 100%;
      max-height: calc(72.75rem / 1.95);
      min-height: calc(72.75rem / 1.95);
    }

    &.fullwidth {
      [data-flight-image-container] {
        max-height: calc(100vw / 1.85);
        min-height: calc(100vw / 1.85);
      }
    }
    ${theme.below.lg} {
      [data-flight-image-container] {
        height: calc(100vw * 1.1) !important;
        max-height: calc(100vw * 1.1) !important;
        min-height: calc(100vw * 1.1) !important;
      }
    }
  }
`;

export const ProductsPopupGridItem = ({
  products,
  desktopImage,
  mobileImage,
  fullWidth,
  indexClassName
}) => {
  const [open, setOpen] = useState(false);
  const imageSizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];

  const handleTriggerPopup = () => {
    setOpen(!open);
  };

  const noProductsCallback = () => {
    // autoclose popup if products are missing
    setTimeout(function () {
      setOpen(false);
    }, 3000);
  };

  const productList = products?.value?.split(',')?.map(prod => {
    return prod?.trim();
  });
  return (
    <>
      <ProductsPopupGridItemWrapper
        className={cx(
          'div-' + numberToSpelledText(indexClassName?.value),
          fullWidth?.value && 'fullwidth'
        )}
        onClick={() => handleTriggerPopup()}
      >
        <CartCircle />

        <Above breakpoint="lg">
          {matches => (
            <Image
              sizes={imageSizes}
              src={matches ? desktopImage?.value : mobileImage?.value}
              fillAvailableSpace
              aspect={"6:10"}
            />
          )}
        </Above>
      </ProductsPopupGridItemWrapper>
      {open && (
        <PopupContent open={open} setOpen={setOpen}>
          <AddToCartList
            title={'Shop the look'}
            products={productList}
            noProductsCallback={noProductsCallback}
          ></AddToCartList>
        </PopupContent>
      )}
    </>
  );
};
