import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

const TitleAndLinkWrapper = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;

  ${theme.below.lg} {
    padding: 0 20px;
  }

  .title-rows {
    display: flex;
    flex-direction: column;
  }

  ${theme.below.sm} {
    flex-direction: column;

    .title-rows {
      align-self: start;
      h2 {
      }
    }
  }

  h2 {
    font-size: ${theme.fontSizes.tinyTitle};
    line-height: ${theme.lineHeight.tinyTitle};
    font-weight: 400;
    font-family: ${theme.fonts.title};
  }

  .link-evaluator {
    width: fit-content;
    p {
      text-decoration: underline;
      color: black;
      font-size: ${theme.fontSizes.paragraph};
      line-height: ${theme.lineHeight.paragraph};
      margin-bottom: 2px;
      :hover,
      :active {
        opacity: 0.7;
      }
    }
  }
`;

export const TitleAndLink = ({ title, secondRow, linkText, link }) => {
  return (
    <TitleAndLinkWrapper className="title-and-link">
      <div className="title-rows">
        {title?.value && <h2>{title?.value}</h2>}
        {secondRow?.value && <h2>{secondRow?.value}</h2>}
      </div>
      {linkText?.value && (
        <LinkEvaluator link={link}>
          <p>{linkText?.value}</p>
        </LinkEvaluator>
      )}
    </TitleAndLinkWrapper>
  );
};
