import CartProvider from '@jetshop/core/components/Query/CartProvider'
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger'
import { styled } from 'linaria/react'
import React, { useEffect } from 'react'
import { ReactComponent as CartIcon } from '../../svg/ShoppingCart.svg'
import useHeaderHeight from '../hooks/useHeaderHeight'
import Badge from '../ui/Badge'
import cartQuery from './queries/cartQuery.gql'
import { FindifyCartTracker } from '../Findify/Tracking'

const Button = styled('button')`
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  position: relative;
`

function CartButton({
  target,
  itemsInCart,
  className,
  forceClose,
  handleForceClose
}) {
  useEffect(() => {
    if (target.isOpen) {
      // Closing Cart flyout if open when Force close is triggered from outside
      target.hideTarget()
    }

    // Reset force close handler.
    handleForceClose(false)
  }, [forceClose]) // eslint-disable-line

  function handleClick() {
    if (target.isOpen) {
      target.hideTarget()
    } else {
      // Closing favorites flyout if open
      handleForceClose(true)

      // Timeout is needed to avoid react render transition at the same time.
      setTimeout(() => {
        target.showTarget()
      }, 1)
    }
  }

  return (
    <Button
      className={className}
      data-testid="cart-button"
      onClick={handleClick}
    >
      <div className="badge-svg-wrapper" style={{ position: 'relative' }}>
        {itemsInCart > 0 && <Badge cartBadge={true} text={itemsInCart} />}
        <CartIcon />
      </div>
    </Button>
  )
}

function CartButtonFlyout({ className, forceClose, handleForceClose }) {
  const topPosition = useHeaderHeight()
  return (
    <CartProvider query={cartQuery}>
      {result => {
        const itemsInCart = result?.data?.cart?.totalQuantity || 0
        return (
          <>
            <FindifyCartTracker cartData={result?.data?.cart} />
            <DrawerTrigger
              preventOverflow={true}
              id="cart-drawer"
              coverStyles={{ top: topPosition }}
            >
              {drawer => (
                <CartButton
                  data-testid="cart-button"
                  id="cart-button"
                  className={className}
                  target={drawer}
                  itemsInCart={itemsInCart}
                  forceClose={forceClose}
                  handleForceClose={handleForceClose}
                />
              )}
            </DrawerTrigger>
          </>
        )
      }}
    </CartProvider>
  )
}

export default CartButtonFlyout
