import FlyoutMenu from './CategoryMenu/FlyoutMenu';
import { Above } from '@jetshop/ui/Breakpoints';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Notifications } from '../Notifications';
import homeCategoriesQuery from './Categories/HomeCategoriesQuery.gql';
import ChannelBanner from './RecommendedChannel/ChannelBanner';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import CartFlyout from '../../Cart/CartFlyout';
import CampaignBarQuery from './CampaignBarQuery.gql';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { allContentComponents } from '../../ContentComponents/ContentComponents';
import FavouritesFlyout from '../../ProductList/FavouritesFlyout';

export const HeaderCampaignBar = React.memo(() => {
  const { data } = useQuery(CampaignBarQuery, {
    variables: {
      id: 176
    }
  });
  const items = data?.category?.data?.items;

  return (
    <>
      {items && (
        <ContentRenderer items={items} components={allContentComponents} />
      )}
    </>
  );
});

export default function Header() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });

  return (
    <>
      <ChannelBanner />
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderCampaignBar />
              <DesktopHeader categories={result} />
            </>
          ) : (
            <>
              <HeaderCampaignBar />
              <MobileHeader categories={result} />
            </>
          )
        }
      </Above>
      <CartFlyout />
      <FavouritesFlyout />
      <FlyoutMenu left={true} data={result.data} />
      <Notifications />
    </>
  );
}
