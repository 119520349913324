import React, { useEffect, useState } from 'react'
import { styled } from 'linaria/react'
import { cx } from 'linaria'
import { Above } from '@jetshop/ui/Breakpoints'
import { LinkEvaluator } from '../../utils/LinkEvaluator'
import { BoxContent, BoxContentType } from './BoxContent'
import ReactPlayer from 'react-player/youtube'

const YoutubeVideoRow = styled('div')`
  margin: 0 auto;
  max-width: calc(80rem + 40px);
  width: 100%;
  position: relative;

  &:not(.fullwidth) {
    padding: 0 20px;
  }

  &.fullwidth {
    max-width: 100%;
  }

  .youtube {
    width: 100%;
    height: 100%;
    position: relative;
  }
`
const YoutubeVideoWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.bottompadding}%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`

// Use Youtubes share url (e.g https://youtu.be/<id-here>)
export const YoutubeVideo = ({
  fullWidth,
  desktopVideo,
  mobileVideo,
  link,
  textColor,
  topText,
  topTextFamily,
  middleText,
  middleTextFamily,
  bottomText,
  bottomTextFamily,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition,
  autoplay = { value: true } // not in admin
}) => {
  const [bottomPadding, setBottomPadding] = useState()
  const [above, setAbove] = useState(false)

  const videoi = above
    ? desktopVideo?.value?.split('https://youtu.be/')?.[1]
    : mobileVideo?.value?.split('https://youtu.be/')?.[1]

  // Check if there is a youtube short video
  const shortId = above
    ? desktopVideo?.value
        ?.split('https://youtube.com/shorts/')?.[1]
        ?.replace('?feature=share', '')
    : mobileVideo?.value
        ?.split('https://youtube.com/shorts/')?.[1]
        ?.replace('?feature=share', '')

  useEffect(() => {
    // Fetches video data to set bottompadding for correct ratio
    const fetchVideoData = videoId => {
      return fetch(
        `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`
      )
        .then(response => response.json())
        .then(data => setBottomPadding(videoRatioToPercent(data)))
        .catch(error => console.error(error))
    }

    fetchVideoData(videoi ?? shortId)
  }, [above])

  const videoRatioToPercent = vData => {
    if (vData?.height && vData.width) {
      // -1 is a dirty fix to not get black border around the video
      return ((vData?.height - 1) / (vData.width - 1)) * 100
    }
    return null
  }

  if (!bottomPadding) {
    return null
  }

  const videoProps = {
    height: 'auto',
    width: '100%',
    playing: !!autoplay?.value, // sets autoplay
    loop: !!autoplay?.value, // loops when autoplay
    controls: false,
    volume: !!autoplay?.value ? 0 : 50, //sets volyme of no autoplay
    muted: !!autoplay?.value //unmutes if no autoplay
  }

  return (
    <Above breakpoint="lg">
      {match => (
        <YoutubeVideoRow className={cx(fullWidth?.value && 'fullwidth')}>
          {setAbove(match)}
          <LinkEvaluator link={link}>
            <YoutubeVideoWrapper
              className={cx('youtube')}
              bottompadding={shortId ? '178' : bottomPadding}
            >
              <ReactPlayer
                url={`https://youtu.be/${videoi ?? shortId}`}
                {...videoProps}
              />
              <BoxContent
                verticalPosition={verticalPosition}
                horizontalPosition={horizontalPosition}
                textColor={textColor}
                topText={topText}
                topTextFamily={topTextFamily}
                middleText={middleText}
                middleTextFamily={middleTextFamily}
                bottomText={bottomText}
                bottomTextFamily={bottomTextFamily}
                buttonTheme={buttonTheme}
                buttonText={buttonText}
                handleDummyButtonClick={() => {}}
                forType={BoxContentType.HERO}
              />
            </YoutubeVideoWrapper>
          </LinkEvaluator>
        </YoutubeVideoRow>
      )}
    </Above>
  )
}
