import { useState, useEffect } from 'react';

/**
 * The useDarkMode function returns an object with boolean value that indicates whether the
 * user's browser is in dark mode.
 *
 * @return An object with the following properties: darkMode, lightMode
 *
 * @docauthor Anders Zetterström
 */
export const useDarkMode = (lightFaviconFolderPath, darkFaviconFolderPath) => {
  const [darkModeActive, setDarkModeActive] = useState(false);
  const [lightModeActive, setLightModeActive] = useState(false);
  const [folderPath, setFolderPath] = useState('');

  useEffect(() => {
    const isDarkModeActive =
      typeof window !== 'undefined' &&
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;

    setFolderPath(
      (isDarkModeActive
        ? darkFaviconFolderPath ?? lightFaviconFolderPath
        : lightFaviconFolderPath ?? darkFaviconFolderPath) ?? ''
    );
    setDarkModeActive(isDarkModeActive);
    setLightModeActive(!isDarkModeActive);
  }, [lightFaviconFolderPath, darkFaviconFolderPath]);

  return {
    darkMode: darkModeActive,
    lightMode: lightModeActive,
    faviconFolderPath: folderPath
  };
};
