import React from 'react';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { HCButton } from '../ui/HCButton';
import { setVariableFromProp } from '../../utils/HelpFunctions';
import { injectContentPropsToChild } from '../../utils/ContentProp';

const LinkRowMaxWidth = styled(MaxWidth)`
  justify-content: center;
  flex-direction: row;
  ${theme.below.lg} {
    padding: 0;
  }
`;

const LinkRowContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 700px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  ${theme.below.lg} {
    padding: 0 10px;
    white-space: nowrap;
    max-width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;

export const LinkRow = ({ buttonTheme, children }) => {
  const btnTheme = setVariableFromProp(buttonTheme);

  const injectedChildren = children?.map(child => {
    return injectContentPropsToChild(child, [
      {
        name: 'buttonTheme',
        value: btnTheme ?? 'BEIGE'
      }
    ]);
  });
  return (
    <LinkRowMaxWidth>
      <LinkRowContainer>{injectedChildren}</LinkRowContainer>
    </LinkRowMaxWidth>
  );
};

const LinkRowItemWrapper = styled('div')``;

export const LinkRowItem = ({ text, link, buttonTheme = 'BEIGE' }) => {
  const btnText = setVariableFromProp(text);
  const btnLink = setVariableFromProp(link);
  const btnTheme = setVariableFromProp(buttonTheme);
  return (
    <LinkRowItemWrapper>
      <HCButton link={btnLink} className={btnTheme}>
        {btnText}
      </HCButton>
    </LinkRowItemWrapper>
  );
};
