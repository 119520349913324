import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import ModalContext from '@jetshop/ui/Modal/ModalContext';

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 30px 8px 31px;
    display: inline-block;
    color: black;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.5px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: ${theme.colors.brown};
      height: 0;
      transition: all, 0.2s ease;
    }
    &:hover {
      color: ${theme.colors.brown};
      &:after {
        height: 3px;
      }
    }

    &.active {
      color: ${theme.colors.brown};
      &:after {
        height: 3px;
      }
    }
    &.red {
      color: ${theme.colors.red};
    }
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props?.activeCategories?.length > 0 &&
              props?.activeCategories[0]?.subcategories?.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  const { hideTarget } = useContext(ModalContext);
  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => {
        props.setActiveCategory(cat);
        // Closes flyout if open
        hideTarget();
      }}
    >
      {cat?.id === 158 ? (
        <Link to="/inspirasjon-1" onClick={() => props.setActiveCategory(cat)}>
          {cat.name}
        </Link>
      ) : (
        <CategoryLink
          // When following a category link, close the menu
          onClick={props.clearActiveCategories}
          category={cat}
          className={cat.name === 'På salg' ? 'red' : ''}
        >
          {cat.name}
        </CategoryLink>
      )}
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
