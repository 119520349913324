import React from 'react';
import Image from '@jetshop/ui/Image';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { LinkEvaluator } from '../../utils/LinkEvaluator';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent, BoxContentType } from './BoxContent';

export const HeroMaxWidth = styled(MaxWidth)`
  ${theme.below.lg} {
    padding: 0;
    max-width: 100%;
  }

  &.overlay {
    [data-flight-image-container] {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
        pointer-events: none;
      }
    }
  }
`;

export const ImageHero = ({
  fullWidth,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  link,
  textColor,
  topText,
  topTextFamily,
  middleText,
  middleTextFamily,
  bottomText,
  bottomTextFamily,
  buttonText,
  buttonTheme,
  horizontalPosition,
  verticalPosition,
  desktopPadding,
  mobilePadding,
  overlay
}) => {
  const handleDummyButtonClick = () => {
    console.log('Dummy button clicked in image hero');
  };

  return (
    <Above breakpoint="lg">
      {matches => (
        <HeroMaxWidth
          style={{
            maxWidth: fullWidth?.value ? '100%' : '80rem',
            padding: `0 ${
              (matches ? desktopPadding?.value : mobilePadding?.value) ?? 0
            }px`
          }}
          className={overlay?.value ? 'overlay' : ''}
        >
          <LinkEvaluator link={link}>
            <Image
              sizes={[1, 1, 1, 1, 1]}
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={
                matches ? desktopImageRatio?.value : mobileImageRatio?.value
              }
              cover
            />
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              textColor={textColor}
              topText={topText}
              topTextFamily={topTextFamily}
              middleText={middleText}
              middleTextFamily={middleTextFamily}
              bottomText={bottomText}
              bottomTextFamily={bottomTextFamily}
              buttonTheme={buttonTheme}
              buttonText={buttonText}
              handleDummyButtonClick={handleDummyButtonClick}
              forType={BoxContentType.HERO}
            />
          </LinkEvaluator>
        </HeroMaxWidth>
      )}
    </Above>
  );
};
