import React, {useContext, useEffect} from 'react';
import {waitForFindify} from '@findify/react-bundle';
import {useLocation} from 'react-router';
import {getBaseItemId} from "./FindifyProvider";
import {useTrackProductAdd} from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils'


// FINDIFY PAGE VIEW TRACKING
export const FindifyPageTracker = ({product, selectedVariation}) => {
  const findifyTrackingContext = useContext(FindifyTrackingContext);
  const location = useLocation();
  const {pathname} = location;

  const triggerFindifyPageTracking = async () => {
    const {analytics} = await waitForFindify();

    // REGULAR PAGES, JUST SEND VIEW PAGE EVENT
    if (!product) {
      analytics.sendEvent('view-page');
    }
    // PRODUCT PAGE - SEND MORE DATA
    else {
      // TRANSFORM ARTICLE NUMBER IF NECESSARY
      const baseItemId = getBaseItemId(product.articleNumber);

      // USE FIRST VARIANT IF NO SELECTED VARIANT
      // USE REGULAR ART NO AS VARIANT ID FOR PRODS WITHOUT VARIANTS
      let findifyVariantId = selectedVariation
        ? selectedVariation.articleNumber
        : null;
      if (!findifyVariantId) {
        if (product.variants?.values.length) {
          findifyVariantId = product.variants.values[0].articleNumber;
        }
        else {
          findifyVariantId = product.articleNumber;
        }
      }
      const trackingObject = {
        item_id: baseItemId,
        variant_item_id: findifyVariantId
      };

      analytics.sendEvent('view-page', trackingObject);
    }
  };

  useEffect(() => {
    if (pathname !== findifyTrackingContext?.lastTrackedPath && product) {
      // STORE TRACKED PATHNAME
      findifyTrackingContext?.setLastTrackedPath(pathname);

      // TRIGGER TRACKING
      triggerFindifyPageTracking();
    }
  });
  return null;
};

// FINDIFY CART TRACKER, CLUNKY SETUP B/C CART ITEM STATE
// SOMETIMES JUMPS AROUND BEFORE SETTLING ON NEW VALUE
let cartItemQtyCounter = 0;
let timeoutVar = null;
export const FindifyCartTracker = ({cartData}) => {
  clearTimeout(timeoutVar);
  timeoutVar = setTimeout(function () {

    if (cartData && cartData.totalQuantity !== cartItemQtyCounter) {
      cartItemQtyCounter = cartData.totalQuantity;
      triggerFindifyCartTracking(cartData.items);
    }
  }, 1000);
  return null;
};

const triggerFindifyCartTracking = async cartItems => {
  let lineItems = [];
  cartItems.forEach((item, index) => {
    const baseItemId = getBaseItemId(item.articleNumber);

    lineItems.push({
      item_id: baseItemId,
      quantity: item.quantity,
      unit_price: item.product.price.incVat,
      variant_item_id: item.articleNumber
    });
  });

  const {analytics} = await waitForFindify();
  analytics.sendEvent('update-cart', {
    line_items: lineItems
  });
};

// TRACK FINDIFY PRODUCT ADD TO CART
// ADDED TIME STAMP B/C NOTIFICATION TRIGGERS MULTIPLE TIMES
let lastEventId = '0';
export const AddToCartTracker = ({ cartItem, eventId }) => {
  const trackAddedItem = useTrackProductAdd(cartItem.variant || cartItem);
  if (lastEventId !== eventId) {
    trackAddedItem(cartItem, 1);
  }
  lastEventId = eventId;
  return null;
};


// TRACKING CONTEXT - FOR AVOIDING MULTIPLE IDENTICAL VIEW PAGE EVENTS BEING SENT
export const FindifyTrackingContext = React.createContext(null);
