import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

const ContentPlacerWrapper = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 1;

  padding: 20px;
  &.TOP {
    align-items: flex-start;
  }
  &.MIDDLE {
    align-items: center;
  }

  &.BOTTOM {
    align-items: flex-end;
  }

  &.LEFT {
    * {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;

    * {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.RIGHT {
    justify-content: flex-end;
    * {
      text-align: right;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

// Position to relative parent
export const ContentPlacer = ({
  verticalPosition,
  horizontalPosition,
  children
}) => {
  return (
    <ContentPlacerWrapper
      className={cx(verticalPosition?.value, horizontalPosition?.value)}
    >
      {children}
    </ContentPlacerWrapper>
  );
};
