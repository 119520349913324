import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Close } from '../../svg/close_new.svg';
import { theme } from '../Theme';
import ModalContext from '@jetshop/ui/Modal/ModalContext';
import { injectContentPropsToChild } from '../../utils/ContentProp';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .campaign-bar-item {
    --parts: ${props => props.parts};
    width: calc(100% / var(--parts));
  }
`;

const CampaignBarItemContainer = styled('div')`
  background: ${props => props.backgroundcolor};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 0 0.5rem;

  ${theme.below.lg} {
    min-height: 36px;
    text-align: center;
    padding: 10px 25px;
  }

  a,
  p {
    font-style: normal;
    font-size: ${props => props.size};
    font-family: ${props => props.family};
    text-decoration: none;
    font-weight: bold;
    line-height: 100%;
    color: ${props => props.textcolor};
  }
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .icon-wrapper {
    margin-right: 11px;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 16px !important;
      height: auto;
      use {
        fill: #666666;
      }
    }
  }
`;

const SliderContainer = styled('div')`
  position: relative;
`;

export const CampaignBarItem = ({
  backgroundColor,
  link,
  text,
  textColor,
  fontSize,
  fontFamily,
  hideTarget
}) => {
  return (
    <CampaignBarItemContainer
      size={fontSize?.value}
      family={fontFamily?.value}
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        link?.value?.includes('http') ? (
          <a
            href={link?.value}
            target="_blank"
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{ __html: text?.value }}
          />
        ) : (
          <Link
            onClick={hideTarget?.value}
            to={link?.value}
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBar = React.memo(
  ({ closeCampaignBarOption, children }) => {
    const [cookies, setCookie] = useCookies();
    const hideCampaignBar = cookies?.hideCampaignBar;
    const showCloseBtn = closeCampaignBarOption?.value === 'YES';

    const { hideTarget } = useContext(ModalContext);

    const mobileSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500
    };

    const handleCloseClick = () => {
      setCookie('hideCampaignBar', true, [{ path: '/' }]);
    };

    const CloseButton = () =>
      showCloseBtn ? (
        <CloseBtn
          onClick={() => {
            handleCloseClick();
          }}
        >
          <div className={'icon-wrapper'}>
            <Close />
          </div>
        </CloseBtn>
      ) : null;
    // Closes flyout if open
    const editedChildren = children?.map(child => {
      return injectContentPropsToChild(child, [
        {
          name: 'hideTarget',
          value: hideTarget
        }
      ]);
    });

    return (
      <>
        {!hideCampaignBar && (
          <Above breakpoint="lg">
            {matches =>
              matches ? (
                <CampaignBarContainer
                  id="campaign-bar"
                  parts={children?.length ?? 1}
                >
                  <CloseButton />

                  {editedChildren}
                </CampaignBarContainer>
              ) : (
                <SliderContainer id="campaign-bar">
                  <CloseButton />
                  <Slider {...mobileSettings}>{editedChildren}</Slider>
                </SliderContainer>
              )
            }
          </Above>
        )}
      </>
    );
  }
);
