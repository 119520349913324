import PropTypes from 'prop-types';
import React, { useState } from 'react';
import StockOrb from './StockOrb';
import { cx } from 'linaria';
import { styled } from 'linaria/react';
import { ReactComponent as NewStore } from '../../../svg/NewStore.svg';
import { theme } from '../../Theme';
import { PopupContent } from '../../ui/PopupContent';
import { GetInStore } from '../GetInStore/GetInStore';
import { useModifiedStockStatus } from './useModifiedStockStatus';

const StockStatusWrapper = styled('div')`
  font-size: 11px;
  line-height: 11px;
  display: flex;
  flex-wrap: wrap;
  ${theme.below.lg} {
    font-size: 9px;
    line-height: 9px;
  }

  > div {
    margin-right: 16px;
    margin-bottom: 14px;
  }

  .status-wrapper {
    display: flex;
    align-items: center;
  }

  .text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .usp {
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
      height: 15px;
    }

    &.link {
      cursor: pointer;

      :hover,
      :active {
        opacity: 0.6;
      }
    }
  }
  .tiny-orb {
    height: 8px;
    width: 8px;
  }
`;

const StockStatusIndicator = ({
  product,
  status,
  text,
  tiny,
  hideDelivery,
  noStatusModification,
  ...props
}) => {
  const [runAction, setRunAction] = useState(false);
  const { modifiedStockStatus, modifiedStockText } = useModifiedStockStatus(
    text,
    status
  );

  const isActionActive = product?.customFields.find(
    customField => customField.key === 'kjopoghent'
  )?.boolValue; // find something to case on

  return (
    <StockStatusWrapper className={tiny && 'tiny'} {...props}>
      <div className="status-wrapper">
        <StockOrb
          className={tiny && 'tiny-orb'}
          status={noStatusModification ? status : modifiedStockStatus}
        />
        <span>{noStatusModification ? text : modifiedStockText}</span>
      </div>
      {!tiny && isActionActive && (
        <div
          className={cx('usp', 'link')}
          onClick={() => {
            setRunAction(true);
          }}
        >
          <NewStore />
          <p>{'Sjekk lager i butikk'}</p>
        </div>
      )}

      {runAction && (
        <PopupContent type={'curtain'} open={runAction} setOpen={setRunAction}>
          <GetInStore
            product={product}
            canExpand={false}
            title="Lagerstatus i butikk"
            getInStoreCallback={() => {
              console.warn('should nbe able to click store card buy button');
            }}
          />
        </PopupContent>
      )}
    </StockStatusWrapper>
  );
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;
