import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity'
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity'
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart'
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant'
import Image from '@jetshop/ui/Image'
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout'
import { Price } from '@jetshop/ui/Price'
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer'
import { styled } from 'linaria/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as CrossIcon } from '../../svg/MenuClose.svg'
import StockOrb from '../ProductPage/StockStatus/StockOrb'
import { useModifiedStockStatus } from '../ProductPage/StockStatus/useModifiedStockStatus'
import cartQuery from './queries/cartQuery.gql'
import decrementQuantityMutation from './queries/decrementQuantity.gql'
import incrementQuantityMutation from './queries/incrementQuantity.gql'
import removeFromCartMutation from './queries/removeFromCart.gql'

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 30px;
`

const ProductName = styled('div')`
  font-weight: normal;
  padding-right: 30px;
  .attribute {
    display: block;
    font-size: 12px;
    margin-top: 2px;
  }
  a {
    color: black;
    text-decoration: none;
  }
  h2 {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.2px;
  }
`

const ProductDetail = styled('section')`
  position: relative;
  flex: 1 1 70%;
  padding: 0 0 35px 10px;

  .status-wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 3px;
    span {
      font-size: 10px;
      display: inline-block;
      line-height: 1.2;
      &.orb {
        flex: none;
        height: 8px;
        width: 9px;
      }
    }
  }
`

const ProductImage = styled('div')`
  flex: 1 1 25%;
`

const ItemDetails = styled('div')`
  position: relative;
`

const LinePrice = styled(Price)`
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
`

const AdjustQty = styled('div')`
  position: absolute;
  bottom: 0;
  left: 10px;
  display: flex;
  /* Plus/minus buttons */
  button,
  span {
    background: #f9f9f9;
    color: black;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 2px;
  }
`

const RemoveItem = styled('div')`
  text-align: right;
  position: absolute;
  top: -4px;
  right: -4px;
  button {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }
  svg {
    height: 12px;
    width: 12px;
    path {
      fill: black;
    }
  }
`

const CartItem = ({ item, className = '', allItems }) => {
  const { isVariant, variantImage, variantValues, hasVariantImage } =
    getCartItemVariant(item)

  const stockStatus = useStockStatus(item.product)
  const { modifiedStockStatus, modifiedStockText } = useModifiedStockStatus(
    stockStatus?.text,
    stockStatus?.status
  )

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  })

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  })

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  })

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  )

  const getRelatedItems = () => {
    let relationItems
    if (item?.customerComments.length > 0) {
      const relation = item?.customerComments.find(
        comment => comment?.name === 'relation'
      )?.value

      relationItems = allItems.filter(item => {
        if (item?.customerComments.length > 0) {
          return (
            item?.customerComments.find(comment => comment?.name === 'relation')
              ?.value === relation
          )
        }
      })
    } else {
      relationItems = [item]
    }
    return relationItems
  }

  const handleRemoveFromCart = () => {
    removeFromCart({ itemId: item?.id, product: item?.product })
  }

  const handleDecrementQuantity = () => {
    item?.quantity !== 1 && decrementQuantity({ itemId: item?.id })
  }

  const handleIncrementQuantity = () => {
    incrementQuantity({ itemId: item?.id })
  }

  const getInStore = item?.customerComments?.find(
    obj => obj?.name === 'Klikk og hent'
  )

  return (
    <Wrapper className={className}>
      <ProductImage>
        {item?.product?.images?.length > 0 && (
          <Image
            aspect="1:1"
            sizes="5rem"
            src={
              hasVariantImage
                ? variantImage?.url
                : item?.product?.images[0]?.url
            }
            alt={
              hasVariantImage
                ? variantImage?.alt
                : item?.product?.images[0]?.alt
            }
            quality={80}
          />
        )}
      </ProductImage>
      <ProductDetail>
        <ItemDetails>
          <RemoveItem>
            <button
              onClick={handleRemoveFromCart}
              data-testid="remove-from-cart"
            >
              <CrossIcon />
            </button>
          </RemoveItem>

          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item?.product?.primaryRoute?.path}>
                  <h2 data-testid="item-name">
                    {item?.product?.name} {item?.product?.subName}
                  </h2>
                  {isVariant && (
                    <ul
                      style={{
                        marginTop: '0'
                      }}
                    >
                      {variantNamesValues.map(
                        (variantOptionNameValue, index) => (
                          <li className="attribute" key={index}>
                            {variantOptionNameValue}
                          </li>
                        )
                      )}
                    </ul>
                  )}
                </Link>
              </ProductName>
            )}
          </FlyoutTrigger>
        </ItemDetails>

        <LinePrice
          data-testid="item-price"
          price={item?.total}
          previousPrice={item?.previousTotal}
        />

        <div className="status-wrapper">
          <StockOrb
            className="orb"
            status={getInStore ? 'inStock' : modifiedStockStatus}
          />
          <span className="text">
            {getInStore
              ? `${getInStore?.name}: ${getInStore?.value}`
              : modifiedStockText}
          </span>
        </div>

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item?.quantity === 1}
            onClick={handleDecrementQuantity}
          >
            -
          </button>
          <span data-testid="item-quantity">{item?.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={handleIncrementQuantity}
          >
            +
          </button>
        </AdjustQty>
      </ProductDetail>
    </Wrapper>
  )
}

export default CartItem
