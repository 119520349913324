import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CrossIcon } from '../../svg/MenuClose.svg';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { useModifiedStockStatus } from '../ProductPage/StockStatus/useModifiedStockStatus';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 30px;
`;

const ProductName = styled('div')`
  font-weight: normal;
  padding-right: 30px;
  .attribute {
    display: block;
    font-size: 12px;
    margin-top: 2px;
  }
  a {
    color: black;
    text-decoration: none;
  }
  h2 {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.2px;
  }
`;

const ProductDetail = styled('section')`
  position: relative;
  flex: 1 1 70%;
  padding: 0 0 0 10px;
`;

const ProductImage = styled('div')`
  flex: 1 1 25%;
`;

const ItemDetails = styled('div')`
  position: relative;
`;

const LinePrice = styled(Price)`
  font-size: 13px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 10px;
`;

const RemoveItem = styled('div')`
  text-align: right;
  position: absolute;
  top: -4px;
  right: -4px;
  button {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    outline: none;
    border: none;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }
  svg {
    height: 12px;
    width: 12px;
    path {
      fill: black;
    }
  }
`;

export function Product({ product, listId, className = '' }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (product.isVariant && product.variant.images) {
    productForDisplay.images = product.variant.images;
  }

  const { modifiedStockStatus } = useModifiedStockStatus(
    product.stockStatus?.text
  );

  return (
    <>
      <Wrapper className={className}>
        <ProductImage>
          {product?.images?.length > 0 && (
            <Image
              aspect="1:1"
              sizes="5rem"
              src={product.images[0].url}
              alt={product.images[0].alt}
              quality={80}
            />
          )}
        </ProductImage>
        <ProductDetail>
          <ItemDetails>
            <RemoveItem>
              <RemoveFromList
                listId={listId}
                articleNumber={product.articleNumber}
                variant={product.variant}
              />
            </RemoveItem>

            <FlyoutTrigger id="favourites-drawer">
              {flyout => (
                <ProductName onClick={flyout.hideTarget}>
                  <Link to={product.primaryRoute.path}>
                    <h2 data-testid="item-name">
                      {product.name} {product.subName}
                    </h2>
                  </Link>
                </ProductName>
              )}
            </FlyoutTrigger>
          </ItemDetails>

          <LinePrice
            data-testid="item-price"
            price={product.price}
            previousPrice={product.previousPrice}
          />

          <div>
            {product.hasVariants && (
              <div className="select-variant">
                <SelectVariant listId={listId} product={product} />
              </div>
            )}
            {product.validation.status === 'valid' &&
            modifiedStockStatus !== 'outOfStock' ? (
              <AddToCart product={product} />
            ) : (
              <InvalidProduct validation={product.validation} />
            )}
          </div>
        </ProductDetail>
      </Wrapper>
    </>
  );
}

function RemoveFromList({ children, articleNumber, variant, listId }) {
  const { remove } = useProductList(listId);
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button onClick={() => remove(articleNumber, { variantArticleNumber })}>
      <CrossIcon />
    </button>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Visit product page to add'),
    configurations: t('Visit product page to add'),
    package: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}


const invalidProductStyles = css`
  opacity: 0.3;
`;
