import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import StoreLocator from '../../StoreLocator/StoreLocator';

const GetInStoreWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    text-align: center;
    font-family: ${theme.fonts.title};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.infoTitle};
    line-height: 35px;
    margin-bottom: 20px;
  }
`;

export const GetInStore = ({
  getInStoreCallback,
  title,
  canExpand,
  product
}) => {
  return (
    <GetInStoreWrapper>
      <StoreLocator
        canExpand={canExpand}
        title={title}
        product={product}
        getInStoreCallback={e => {
          getInStoreCallback(e);
        }}
      />
    </GetInStoreWrapper>
  );
};
